import { useState } from "react";
import ProductDetail from "../ProductDetail";
import UiTabs from "../../../../Components/Common/Tabs";

const ProductDetailTabs = () => {
  const [activeTab, setActiveTab] = useState("properties");

  const tabs = [
    { id: "properties", title: "Properties" },
    { id: "sku", title: "SKU" },
    { id: "composition", title: "Composition" },
    { id: "measure", title: "Measure" },
    { id: "price", title: "Price" },
    { id: "onlineStore", title: "Online Store" },
    { id: "picture", title: "Picture" },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "properties":
        return <ProductDetail />;
      case "sku":
        return <div>SKU Content</div>;
      case "composition":
        return <div>Composition Content</div>;
      case "measure":
        return <div>Measure Content</div>;
      case "price":
        return <div>Price Content</div>;
      case "onlineStore":
        return <div>Online Store Content</div>;
      case "picture":
        return <div>Picture Content</div>;
      default:
        return <div>Default Content</div>;
    }
  };

  return (
    <>
      <UiTabs tab={activeTab} setTab={setActiveTab} options={tabs} />
      {renderTabContent()}
    </>
  );
};

export default ProductDetailTabs;

import { ReactComponent as OrderIcon } from "./ordersIcon.svg";
import { ReactComponent as LogisticsIcon } from "./logistic-icon.svg";
import { ReactComponent as ProductsIcon } from "./products-icon.svg";
import { ReactComponent as HeadsetsIcon } from "./headsets.svg";
import { ReactComponent as PhoneIcon } from "./phone.svg";
import { ReactComponent as FilterIcon } from "./filteriIcon.svg";
import { ReactComponent as EmailIcon } from "./email.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as DownArrowIcon } from "./downArrow.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import { ReactComponent as DotIcon } from "./dot.svg";
import { ReactComponent as ColumnFilterIcon } from "./column_filter.svg";
import { ReactComponent as OrdercreatedIcon } from "./createicon.svg";
import { ReactComponent as SuccessAlertIcon } from "./successAlertIcon.svg";
import { ReactComponent as WarningAlertIcon } from "./warningAlertIcon.svg";
import { ReactComponent as PrimaryAlertIcon } from "./primaryAlertIcon.svg";
import { ReactComponent as DangerAlertIcon } from "./dangerAlertIcon.svg";
import { ReactComponent as LinesIcon } from "./3-lines.svg";
import { ReactComponent as SquareIcon } from "./4-squares.svg";
import { ReactComponent as DoubleCheckLine } from "./check-double-line.svg";
import { ReactComponent as PlusLine } from "./plus.line.svg";
import { ReactComponent as TimeBack } from "./time-back.svg";
import { ReactComponent as Ruller } from "./ruller.svg";
import { ReactComponent as TreeDots } from "./3dots.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as PDF } from "./pdf.svg";
import { ReactComponent as XLS } from "./xls.svg";
import { ReactComponent as XIcon } from "./x-icon.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as Check } from "./check.svg";
import { ReactComponent as Battery1 } from "./battery1.svg";
import { ReactComponent as Battery2 } from "./battery2.svg";
import { ReactComponent as Battery3 } from "./battery3.svg";
import { ReactComponent as Battery4 } from "./battery4.svg";
import { ReactComponent as Battery5 } from "./battery5.svg";
import { ReactComponent as Battery6 } from "./battery6.svg";
import { ReactComponent as Envelope } from "./envelope.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as BurguerSmall } from "./burguer-small.svg";
import { ReactComponent as Filters } from "./filters.svg";
import { ReactComponent as Arrow } from "./pagi-arrow.svg";
import { ReactComponent as LeftArrow } from "./left_arrow.svg";
import { ReactComponent as RightArrow } from "./right_arrow.svg";
import { ReactComponent as PriceIcon } from "./PriceIcon.svg";
import { ReactComponent as GrossMargin } from "./GrossMarginIcon.svg";
import { ReactComponent as AquisitionPrice } from "./AquisitionPriceIcon.svg";
import { ReactComponent as PCM } from "./PCM-PHC-Icon.svg";
import { ReactComponent as SendEmail } from "./sendEmail.svg";
import { ReactComponent as EmailSent } from "./emailSentIcon.svg";
import { ReactComponent as Lock } from "./lockIcon.svg";
import { ReactComponent as Sort } from "./sort.svg";

const icons = {
  orderIcon: {
    icon: <OrderIcon />,
    type: "fill",
  },
  logisticsIcon: {
    icon: <LogisticsIcon />,
    type: "fill",
  },
  productsIcon: {
    icon: <ProductsIcon />,
    type: "fill",
  },
  phoneIcon: {
    icon: <PhoneIcon />,
    type: "fill",
  },
  OrdercreatedIcon: {
    icon: <OrdercreatedIcon />,
  },
  headsetsIcon: {
    icon: <HeadsetsIcon />,
    type: "fill",
  },
  filtersIcon: {
    icon: <FilterIcon />,
    type: "fill",
  },
  DangerAlertIcon: {
    icon: <DangerAlertIcon />,
    type: "fill",
  },
  WarningAlertIcon: {
    icon: <WarningAlertIcon />,
    type: "fill",
  },
  PrimaryAlertIcon: {
    icon: <PrimaryAlertIcon />,
    type: "fill",
  },
  SuccessAlertIcon: {
    icon: <SuccessAlertIcon />,
    type: "fill",
  },
  emailIcon: {
    icon: <EmailIcon />,
    type: "fill",
  },
  searchIcon: {
    icon: <SearchIcon />,
    type: "fill",
  },
  downArrowIcon: {
    icon: <DownArrowIcon />,
    type: "fill",
  },
  closeIcon: {
    icon: <CloseIcon />,
    type: "fill",
  },
  DotIcon: {
    icon: <DotIcon />,
    type: "fill",
  },
  columnFilterIcon: {
    icon: <ColumnFilterIcon />,
    type: "fill",
  },
  linesIcon: {
    icon: <LinesIcon />,
    type: "fill",
  },
  squareIcon: {
    icon: <SquareIcon />,
    type: "fill",
  },
  doubleCheckLine: {
    icon: <DoubleCheckLine />,
    type: "fill",
  },
  plusLine: {
    icon: <PlusLine />,
    type: "fill",
  },
  timeBack: {
    icon: <TimeBack />,
    type: "fill",
  },
  ruller: {
    icon: <Ruller />,
    type: "fill",
  },
  treeDots: {
    icon: <TreeDots />,
    type: "fill",
  },
  trash: {
    icon: <Trash />,
    type: "fill",
  },
  pdf: {
    icon: <PDF />,
    type: "fill",
  },
  xls: {
    icon: <XLS />,
    type: "fill",
  },
  xIcon: {
    icon: <XIcon />,
    type: "fill",
  },
  pencil: {
    icon: <Pencil />,
    type: "fill",
  },
  check: {
    icon: <Check />,
    type: "fill",
  },
  battery1: {
    icon: <Battery1 />,
    type: "fill",
  },
  battery2: {
    icon: <Battery2 />,
    type: "fill",
  },
  battery3: {
    icon: <Battery3 />,
    type: "fill",
  },
  battery4: {
    icon: <Battery4 />,
    type: "fill",
  },
  battery5: {
    icon: <Battery5 />,
    type: "fill",
  },
  battery6: {
    icon: <Battery6 />,
    type: "fill",
  },
  envelope: {
    icon: <Envelope />,
    type: "fill",
  },
  bell: {
    icon: <Bell />,
    type: "fill",
  },
  burguerSmall: {
    icon: <BurguerSmall />,
    type: "fill",
  },
  filters: {
    icon: <Filters />,
    type: "fill",
  },
  arrow: {
    icon: <Arrow />,
    type: "fill",
  },
  leftArrow: {
    icon: <LeftArrow />,
    type: "fill",
  },
  rightArrow: {
    icon: <RightArrow />,
    type: "fill",
  },
  PriceIcon: {
    icon: <PriceIcon />,
    type: "fill",
  },
  GrossMargin: {
    icon: <GrossMargin />,
    type: "fill",
  },
  AquisitionPrice: {
    icon: <AquisitionPrice />,
    type: "fill",
  },
  PCM: {
    icon: <PCM />,
    type: "fill",
  },
  SendEmail: {
    icon: <SendEmail />,
    type: "fill",
  },
  EmailSent: {
    icon: <EmailSent />,
    type: "fill",
  },
  Lock: {
    icon: <Lock />,
  },
  Sort: {
    icon: <Sort />,
    type: "fill",
  },
};

export default icons;

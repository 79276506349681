import React, { useState } from "react";
import BaseButton from "../../Components/Common/Buttons/BaseButton";
import ButtonIcon from "../../Components/Common/Buttons/ButtonIcon";
import UiTabs from "../../Components/Common/Tabs";
import BaseLink from "../../Components/Common/Link";
import BaseCheckBox from "../../Components/Common/Inputs/CheckBox";
import BaseRadioButton from "../../Components/Common/Inputs/RadioButton";
import BaseButtonGroup from "../../Components/Common/Inputs/BaseButtonGroup";
import BreadCrumb from "../../Components/Common/Breadcrumb";
import PaginationComponent from "../../Components/Common/PaginationComponent";
import Badge from "../../Components/Common/Badges/Badge";
import BatteryBadge from "../../Components/Common/Badges/BatteryBadge";
import ColorBadge from "../../Components/Common/Badges/ColorBadge";
import Icon from "../../Components/Common/icon";

const index = () => {
  const [tab, setTab] = useState(1);
  const [check1, setCheck1] = useState(false);
  const [check2, setCheck2] = useState(false);
  const [check3, setCheck3] = useState(false);
  const [check4, setCheck4] = useState(false);
  const [check5, setCheck5] = useState(false);
  const [check6, setCheck6] = useState(false);
  const [check7, setCheck7] = useState(false);
  const [check8, setCheck8] = useState(false);
  const [check9, setCheck9] = useState(false);
  const [check10, setCheck10] = useState(false);
  const [check11, setCheck11] = useState(false);
  const [check12, setCheck12] = useState(false);
  const [radio1, setRadio1] = useState(false);
  const [radio2, setRadio2] = useState(false);
  const [radio3, setRadio3] = useState(false);
  const [radio4, setRadio4] = useState(false);
  const [radio5, setRadio5] = useState(false);
  const [radio6, setRadio6] = useState(false);
  const [radio7, setRadio7] = useState(false);
  const [radio8, setRadio8] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const breadcrumbData = {
    pageTitle: "Orders",
    items: [{ title: "Products", url: "/products" }],
  };

  const items = [
    { id: 1, title: "Properties" },
    { id: 2, title: "Properties" },
  ];

  const buttonGroup = [
    { id: 1, text: "Radio 1", onClick: () => console.log("1") },
    { id: 2, text: "Radio 2", onClick: () => console.log("2") },
    { id: 3, text: "Radio 3", onClick: () => console.log("3") },
  ];

  const buttonGroupIcons = [
    { id: 1, text: "Radio 1", icon: "pencil", onClick: () => console.log("1") },
    { id: 2, text: "Radio 2", icon: "pencil", onClick: () => console.log("2") },
    { id: 3, text: "Radio 3", icon: "pencil", onClick: () => console.log("3") },
  ];

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div
      className="bg-white"
      style={{
        padding: 20,
        gap: 10,
      }}
    >
      <>
        {/* SAVE BUTTON */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Save" />
          </div>
          <div>
            height 30 px <BaseButton height="small" text="Save" />
          </div>
          <div>
            disabled <BaseButton height="small" text="Save" disabled />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Save" type="secondary" />
          </div>
          <div>
            height 30 px
            <BaseButton text="Save" type="secondary" height="small" />
          </div>
          <div>
            disabled
            <BaseButton height="small" type="secondary" text="Save" disabled />
          </div>
        </div>
        {/* CANCEL BUTTON */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Cancel" color="grey" />
          </div>
          <div>
            height 30 px{" "}
            <BaseButton height="small" text="Cancel" color="grey" />
          </div>
          <div>
            disabled
            <BaseButton height="small" text="Cancel" color="grey" disabled />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px{" "}
            <BaseButton text="Cancel" type="secondary" color="grey" />
          </div>
          <div>
            height 30 px
            <BaseButton
              text="Cancel"
              type="secondary"
              height="small"
              color="grey"
            />
          </div>
          <div>
            disabled
            <BaseButton
              height="small"
              type="secondary"
              text="Cancel"
              color="grey"
              disabled
            />
          </div>
        </div>
        {/* WARNING BUTTON */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Warning" color="red" />
          </div>
          <div>
            height 30 px{" "}
            <BaseButton height="small" text="Warning" color="red" />
          </div>
          <div>
            disabled
            <BaseButton height="small" text="Warning" color="red" disabled />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px{" "}
            <BaseButton text="Warning" type="secondary" color="red" />
          </div>
          <div>
            height 30 px
            <BaseButton
              text="Warning"
              type="secondary"
              height="small"
              color="red"
            />
          </div>
          <div>
            disabled
            <BaseButton
              height="small"
              type="secondary"
              text="Warning"
              color="red"
              disabled
            />
          </div>
        </div>
        {/* BUTTONS WITH ICON */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Button" icon="doubleCheckLine" />
          </div>
          <div>
            height 30 px{" "}
            <BaseButton height="small" text="Button" icon="doubleCheckLine" />
          </div>
          <div>
            disabled{" "}
            <BaseButton
              height="small"
              text="Button"
              icon="doubleCheckLine"
              disabled
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px
            <BaseButton text="Button" type="secondary" icon="doubleCheckLine" />
          </div>
          <div>
            height 30 px
            <BaseButton
              text="Button"
              type="secondary"
              height="small"
              icon="doubleCheckLine"
            />
          </div>
          <div>
            disabled
            <BaseButton
              height="small"
              type="secondary"
              text="Button"
              icon="doubleCheckLine"
              disabled
            />
          </div>
        </div>
        {/* BUTTONS WIDTH */}
        Button width
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            <BaseButton
              text="Small"
              type="secondary"
              color="grey"
              width="small"
            />
          </div>
          <div>
            <BaseButton
              text="Medium"
              type="secondary"
              color="grey"
              width="medium"
            />
          </div>
          <div>
            <BaseButton
              type="secondary"
              text="Large"
              color="grey"
              width="large"
            />
          </div>
        </div>
        {/* BUTTONS WITH ICON */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px <BaseButton text="Raw Material" icon="plusLine" />
          </div>
          <div>
            height 30 px{" "}
            <BaseButton height="small" text="Raw Material" icon="plusLine" />
          </div>
          <div>
            disabled
            <BaseButton
              height="small"
              text="Raw Material"
              icon="plusLine"
              disabled
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <div>
            height 36 px
            <BaseButton text="Raw Material" type="secondary" icon="plusLine" />
          </div>
          <div>
            height 30 px
            <BaseButton
              text="Raw Material"
              type="secondary"
              height="small"
              icon="plusLine"
            />
          </div>
          <div>
            disabled
            <BaseButton
              height="small"
              type="secondary"
              text="Raw Material"
              icon="plusLine"
              disabled
            />
          </div>
        </div>
      </>
      <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ButtonIcon icon="linesIcon" />
          <ButtonIcon icon="linesIcon" color="green" />
          <ButtonIcon icon="linesIcon" color="yellow" />
          <ButtonIcon icon="linesIcon" color="red" />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ButtonIcon type="secondary" icon="linesIcon" />
          <ButtonIcon type="secondary" icon="linesIcon" color="green" />
          <ButtonIcon type="secondary" icon="linesIcon" color="yellow" />
          <ButtonIcon type="secondary" icon="linesIcon" color="red" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ButtonIcon type="secondary" icon="squareIcon" />
          <ButtonIcon type="secondary" icon="timeBack" />
          <ButtonIcon type="secondary" icon="ruller" />
          <ButtonIcon type="secondary" icon="treeDots" color="yellow" />
          <ButtonIcon type="secondary" icon="trash" color="red" />
          <ButtonIcon icon="trash" color="red" />
          <ButtonIcon type="secondary" icon="pdf" />
          <ButtonIcon type="secondary" icon="xls" />
          <ButtonIcon type="noBorder" icon="xIcon" />
          <div style={{ backgroundColor: "#566069" }}>
            <ButtonIcon type="noBorder" icon="xIcon" color="black" />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ButtonIcon type="secondary" icon="pencil" color="yellow" />
          <ButtonIcon type="secondary" icon="pencil" color="yellow" disabled />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 10,
          }}
        >
          <ButtonIcon type="secondary" icon="xIcon" color="grey" />
          <ButtonIcon type="secondary" icon="check" color="green" />
          <ButtonIcon type="secondary" icon="check" color="green" disabled />
        </div>
      </div>
      <UiTabs tab={tab} setTab={setTab} options={items} />
      <BaseLink text="Link" link="#" />
      <BaseLink text="Link" icon="pencil" />
      <BaseLink text="Link" link="#" disabled />
      <BaseLink text="Link" icon="pencil" disabled />
      {/* CHECK BOXS */}
      <div style={{ display: "flex", gap: 10, flexDirection: "column" }}>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            label="Label"
            id="label 1"
            value={check1}
            onChange={() => setCheck1(!check1)}
          />
          <BaseCheckBox
            disabled={true}
            label="Label"
            id="label 1"
            value={check1}
            onChange={() => setCheck1(!check1)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            id="label 2"
            value={check2}
            onChange={() => setCheck2(!check2)}
          />
          <BaseCheckBox
            disabled={true}
            id="label 2"
            value={check2}
            onChange={() => setCheck2(!check2)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            label="Primary"
            id="label 3"
            value={check3}
            onChange={() => setCheck3(!check3)}
          />
          <BaseCheckBox
            disabled={true}
            label="Primary"
            id="label 3"
            value={check3}
            onChange={() => setCheck3(!check3)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            id="label 4"
            value={check4}
            onChange={() => setCheck4(!check4)}
          />
          <BaseCheckBox
            disabled={true}
            id="label 4"
            value={check4}
            onChange={() => setCheck4(!check4)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="black"
            label="Secondary"
            id="label 5"
            value={check5}
            onChange={() => setCheck5(!check5)}
          />
          <BaseCheckBox
            type="black"
            disabled={true}
            label="Secondary"
            id="label 5"
            value={check5}
            onChange={() => setCheck5(!check5)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="black"
            id="label 6"
            value={check6}
            onChange={() => setCheck6(!check6)}
          />
          <BaseCheckBox
            type="black"
            disabled={true}
            id="label 6"
            value={check6}
            onChange={() => setCheck6(!check6)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="green"
            label="Seccess"
            id="label 7"
            value={check7}
            onChange={() => setCheck7(!check7)}
          />
          <BaseCheckBox
            type="green"
            disabled={true}
            label="Seccess"
            id="label 7"
            value={check7}
            onChange={() => setCheck7(!check7)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="green"
            id="label 8"
            value={check8}
            onChange={() => setCheck8(!check8)}
          />
          <BaseCheckBox
            type="green"
            disabled={true}
            id="label 8"
            value={check8}
            onChange={() => setCheck8(!check8)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="yellow"
            label="Seccess"
            id="label 9"
            value={check9}
            onChange={() => setCheck9(!check9)}
          />
          <BaseCheckBox
            type="yellow"
            disabled={true}
            label="Seccess"
            id="label 9"
            value={check9}
            onChange={() => setCheck9(!check9)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="yellow"
            id="label 10"
            value={check10}
            onChange={() => setCheck10(!check10)}
          />
          <BaseCheckBox
            type="yellow"
            disabled={true}
            id="label 10"
            value={check10}
            onChange={() => setCheck11(!check10)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="red"
            label="Seccess"
            id="label 11"
            value={check11}
            onChange={() => setCheck11(!check11)}
          />
          <BaseCheckBox
            type="red"
            disabled={true}
            label="Seccess"
            id="label 11"
            value={check11}
            onChange={() => setCheck11(!check11)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseCheckBox
            type="red"
            id="label 12"
            value={check12}
            onChange={() => setCheck12(!check12)}
          />
          <BaseCheckBox
            type="red"
            disabled={true}
            id="label 12"
            value={check12}
            onChange={() => setCheck12(!check12)}
          />
        </div>
      </div>
      {/* RADIO BUTTONS */}
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
            label="Primary"
          />
          <BaseRadioButton
            disabled
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
          />
          <BaseRadioButton
            disabled
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="black"
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
            label="Primary"
          />
          <BaseRadioButton
            disabled
            type="black"
            id="radio 1"
            value={radio1}
            onChange={() => setRadio1(!radio1)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="black"
            id="radio 2"
            value={radio2}
            onChange={() => setRadio2(!radio2)}
          />
          <BaseRadioButton
            disabled
            type="black"
            id="radio 2"
            value={radio1}
            onChange={() => setRadio2(!radio2)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="green"
            id="radio 3"
            value={radio3}
            onChange={() => setRadio3(!radio3)}
            label="Primary"
          />
          <BaseRadioButton
            disabled
            type="green"
            id="radio 3"
            value={radio3}
            onChange={() => setRadio3(!radio3)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="green"
            id="radio 4"
            value={radio4}
            onChange={() => setRadio4(!radio4)}
          />
          <BaseRadioButton
            disabled
            type="green"
            id="radio 4"
            value={radio1}
            onChange={() => setRadio4(!radio4)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="yellow"
            id="radio 5"
            value={radio5}
            onChange={() => setRadio5(!radio5)}
            label="Primary"
          />
          <BaseRadioButton
            disabled
            type="yellow"
            id="radio 5"
            value={radio5}
            onChange={() => setRadio5(!radio5)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="yellow"
            id="radio 6"
            value={radio6}
            onChange={() => setRadio6(!radio6)}
          />
          <BaseRadioButton
            disabled
            type="yellow"
            id="radio 6"
            value={radio1}
            onChange={() => setRadio6(!radio6)}
          />
        </div>

        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="red"
            id="radio 7"
            value={radio7}
            onChange={() => setRadio1(!radio7)}
            label="Primary"
          />
          <BaseRadioButton
            disabled
            type="red"
            id="radio 7"
            value={radio7}
            onChange={() => setRadio7(!radio7)}
          />
        </div>
        <div style={{ display: "flex", gap: 10, flexDirection: "row" }}>
          <BaseRadioButton
            type="red"
            id="radio 8"
            value={radio8}
            onChange={() => setRadio8(!radio8)}
          />
          <BaseRadioButton
            disabled
            type="red"
            id="radio 8"
            value={radio8}
            onChange={() => setRadio8(!radio8)}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "column",
          marginTop: 10,
        }}
      >
        <BaseButtonGroup options={buttonGroup} />
        <BaseButtonGroup options={buttonGroupIcons} />
        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <div>
            <BaseButton text="Save" loading />
          </div>
          <div>
            <BaseButton text="Save" type="secondary" loading />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <div>
            <BaseButton text="Save" height="small" loading />
          </div>
          <div>
            <BaseButton text="Save" height="small" type="secondary" loading />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "row",
            marginTop: 10,
          }}
        >
          <div>
            <BaseButton
              text="Tooltip on Top"
              height="small"
              type="secondary"
              tooltip="Tooltip on Top"
              id={1}
            />
          </div>
          <div>
            <BaseButton
              text="Tooltip on Bottom"
              height="small"
              type="secondary"
              tooltip="Tooltip on Bottom"
              id={2}
              tolltipPosition="bottom"
            />
          </div>
        </div>
        <div style={{ backgroundColor: "#F2F2F2", padding: 20 }}>
          <BreadCrumb breadcrumbData={breadcrumbData} />
        </div>

        <PaginationComponent
          currentPage={currentPage}
          totalPages={10}
          onPageChange={onPageChange}
          //rowsPerPage={rowsPerPage}
          //products={tableInfo}
          totalItems={100}
          //handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <Badge text="New" color="new" type="primary" />
        <Badge text="manufacturing" color="manufacturing" type="primary" />
        <Badge
          text="ready-to-process"
          color="ready-to-process"
          type="primary"
        />
        <Badge text="ready-to-ship" color="ready-to-ship" type="primary" />
        <Badge
          text="ready-to-pick-up"
          color="ready-to-pick-up"
          type="primary"
        />
        <Badge text="shiped" color="shiped" type="primary" />
        <Badge text="delivered" color="delivered" type="primary" />
        <Badge text="open-order" color="open-order" type="primary" />
        <Badge text="closed" color="closed" type="primary" />
        <Badge text="canceled" color="canceled" type="primary" />
        <Badge text="waiting" color="waiting" type="primary" />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <Badge text="New" color="new" type="secondary" />
        <Badge text="manufacturing" color="manufacturing" type="secondary" />
        <Badge
          text="ready-to-process"
          color="ready-to-process"
          type="secondary"
        />
        <Badge text="ready-to-ship" color="ready-to-ship" type="secondary" />
        <Badge
          text="ready-to-pick-up"
          color="ready-to-pick-up"
          type="secondary"
        />
        <Badge text="shiped" color="shiped" type="secondary" />
        <Badge text="delivered" color="delivered" type="secondary" />
        <Badge text="open-order" color="open-order" type="secondary" />
        <Badge text="closed" color="closed" type="secondary" />
        <Badge text="canceled" color="canceled" type="secondary" />
        <Badge text="waiting" color="waiting" type="secondary" />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <Badge text="New" color="new" type="soft" />
        <Badge text="manufacturing" color="manufacturing" type="soft" />
        <Badge text="ready-to-process" color="ready-to-process" type="soft" />
        <Badge text="ready-to-ship" color="ready-to-ship" type="soft" />
        <Badge text="ready-to-pick-up" color="ready-to-pick-up" type="soft" />
        <Badge text="shiped" color="shiped" type="soft" />
        <Badge text="delivered" color="delivered" type="soft" />
        <Badge text="open-order" color="open-order" type="soft" />
        <Badge text="closed" color="closed" type="soft" />
        <Badge text="canceled" color="canceled" type="soft" />
        <Badge text="waiting" color="waiting" type="soft" />
      </div>

      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <BatteryBadge text="0%" type={1} />
        <BatteryBadge text="0% - 10%" type={2} />
        <BatteryBadge text="20% - 40%" type={3} />
        <BatteryBadge text="40% - 60%" type={4} />
        <BatteryBadge text="60% - 80%" type={5} />
        <BatteryBadge text="80% - 100%" type={6} />
      </div>
      <div
        style={{
          display: "flex",
          gap: 10,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <ColorBadge color="#2F56A4" />
        <ColorBadge color="black" />
        <ColorBadge color="#C91F1F" />
        <ColorBadge color="#875555" />
      </div>

      <div
        style={{
          display: "flex",
          gap: 20,
          flexDirection: "row",
          marginTop: 10,
          flexWrap: "wrap",
        }}
      >
        <BaseButton text="Save" counter={4} counterColor="#50CFBD" />
        <BaseButton text="Save" counter={4} counterColor="#F06548" />
        <BaseButton
          text="Save"
          type="secondary"
          counter={4}
          counterColor="#50CFBD"
        />
        <BaseButton text="Mails" alert />
        <BaseButton text="Mails" alertCounter="+99" />
        <ButtonIcon icon="envelope" alert />
        <button className="bell-icon">
          <Icon icon="bell" size={[13, 13]} />
        </button>
        <button className="burguer-icon">
          <Icon icon="burguerSmall" size={[13, 13]} />
          <div className="green-noti" />
        </button>
      </div>
    </div>
  );
};

export default index;

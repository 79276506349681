import React from "react";
import { Input, Label } from "reactstrap";

const BaseRadioButton = ({
  id,
  name,
  label,
  defaultChecked,
  disabled,
  value,
  onChange,
  type,
}) => {
  const handleType = () => {
    switch (type) {
      case "black":
        return "black";
      case "green":
        return "green";
      case "yellow":
        return "yellow";
      case "red":
        return "red";
      default:
        return "";
    }
  };

  const handleDisabled = () => {
    if (disabled) {
      return "disabled";
    }
    return "";
  };

  const handleValue = () => {
    if (value === true) {
      return "base-check-checked";
    }
    return "";
  };

  return (
    <div className="form-check">
      <Input
        className={`base-radio-default form-check-input ${handleType()} ${handleDisabled()} ${handleValue()}`}
        type="radio"
        name={name}
        id={id}
        defaultChecked={defaultChecked}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      <Label
        htmlFor={id}
        className="bodyText_10 form-check-label"
        style={{ marginLeft: 9, marginBottom: 0 }}
      >
        {label}
      </Label>
    </div>
  );
};

export default BaseRadioButton;

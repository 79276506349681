import React from "react";

const index = ({ data }) => {
  return (
    <div style={{ textAlign: "end" }}>
      <span>{data?.price}€</span>
    </div>
  );
};

export default index;

import TextInput from "../Inputs/TextInput/TextInput";

const TextInputAdaptor = ({ input, ...remainingProps }) => (
  // meta can be added to props for error handling
  <TextInput
    {...input}
    {...remainingProps}
    value={input.value}
    onChange={(e) => {
      input.onChange(e.target.value);
    }}
  />
);

export default TextInputAdaptor;

import React from "react";
import { Row, Col, Button } from "reactstrap";
import Alert from "../../Components/Common/Alerts";
import alertsArray from "./alertsdata";
import Imagem1 from "../../assets/imagesNew/alerts/defaultIconAlert.png";
import Imagem2 from "../../assets/imagesNew/alerts/warningDefaultAlert.png";
import Imagem3 from "../../assets/imagesNew/alerts/sucessOrderAlert.png";
import Imagem4 from "../../assets/imagesNew/alerts/cancelOrder.png";
import Imagem5 from "../../assets/imagesNew/alerts/newDeparture.png";
import Imagem6 from "../../assets/imagesNew/alerts/partialDeparture.png";
import Imagem7 from "../../assets/imagesNew/alerts/cancelDeparture.png";
import Imagem8 from "../../assets/imagesNew/alerts/newArrival.png";
import Imagem9 from "../../assets/imagesNew/alerts/partialArrival.png";
import Imagem10 from "../../assets/imagesNew/alerts/cancelArrival.png";
import Imagem11 from "../../assets/imagesNew/alerts/verifyEmail.png";

import {
  showAlert,
  showNotificationAlert,
} from "../../Components/Common/SweetAlert";

const Index = () => {
  const notifications = 2;
  const name = "Tiago Duarte";
  // const imagem = "../../../assets/imagesNew/alerts/profilebackground.png";

  const handleShowAlert = (order) => {
    showAlert(order)
      .then((result) => {
        // Handle result if needed
        console.log(result);
      })
      .catch((error) => {
        // Handle error if needed
        console.error(error);
      });
  };
  const orders = [
    {
      image: Imagem1,
      title: "Order Created !",
      description: "Your order has been successfully created.",
      confirmButtonCss: "btn-soft-info bg-info border-0 fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Continue",
      denyButtonText: "No",
    },
    {
      image: Imagem2,
      title: "Order Canceled !",
      description: "Your order has been canceled.",
      confirmButtonCss: "btn-soft-info bg-info border-0 fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "Continue",
      denyButtonText: "No",
    },
    {
      image: Imagem3,
      title: "Order Created !",
      description: "Your order has been successfully created.",
      confirmButtonCss: "btn-soft-info bg-info border-0 fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showDenyButton: true,
      confirmButtonText: "Continue",
      denyButtonText: "No",
    },
    {
      image: Imagem4,
      title: "Cancel Order !",
      description: "Are you sure you want to cancel your order?",
      confirmButtonCss: "bg-danger border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem5,
      title: "New Departure !",
      description: "Are you sure you want to create a new departure?",
      confirmButtonCss: "bg-info border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem6,
      title: "Partial Departure !",
      description: "Are you sure you want to create a partial departure ?",
      confirmButtonCss: "bg-info border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem7,
      title: "Cancel Departure !",
      description: "Are you sure you want to cancel the departure ?",
      confirmButtonCss: "bg-danger border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem8,
      title: "Create Arrival !",
      description: "Are you sure you want to create a new arrival?",
      confirmButtonCss: "bg-info border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem9,
      title: "Partial Arrival !",
      description: "Are you sure you want to create a partial arrival ?",
      confirmButtonCss: "bg-info border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem10,
      title: "Cancel Arrival !",
      description: "Are you sure you want to cancel the arrival?",
      confirmButtonCss: "bg-danger border-0 Nofocus fontsize-buttons",
      cancelButtonCss:
        "bg-secondary bg-opacity-25 text-secondary Nofocus fontsize-buttons",
      closeButtonCss: "Nofocus close-button",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    },
    {
      image: Imagem11,
      title: "Verify your email !",
      description:
        "We sent a verification to <a href=\"mailto:tomas.oliveira@ad-pulse.com\" class=\"email\">tomas.oliveira@ad-pulse.com</a>. <br /> Please check it.",
      confirmButtonCss: "bg-info border-0 Nofocus fontsize-buttons button-width",
      closeButtonCss: "Nofocus close-button",
      email: "tomas.oliveira@ad-pulse.com",
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: "Verify Email",
      denyButtonText: "No",
      footer:
        "Didn't receive an email? <a class='custom-footer fontsize-footer' href='toemail:tduarte@wearskypro.com'>Resend</a>",
      footerCss: "fontsize-footer",
    },
  ];
  const handleNotificationAlert = () => {
    showNotificationAlert(notifications, name);
  };

  return (
    <div className="page-content bg-white">
      <div className="mt-3">
        <h1>Alerts</h1>
        <Row>
          <Col xl={6}>
            <h6>Info Alert</h6>
            {alertsArray.map((alert, index) => (
              <Alert
                key={index}
                icon={alert.icon}
                color={alert.color}
                borderless={alert.borderless}
                heading={alert.heading}
                paragraph={alert.paragraph}
                text={alert.text}
                additional={alert.additional}
              />
            ))}
          </Col>
        </Row>
        <Row>
          <Col xl={6}>
            <div>
              <h1>NEW</h1>
              <div>
                {orders.map((order, index) => (
                  <Button
                    className="m-1"
                    key={index}
                    onClick={() => handleShowAlert(order)}
                  >
                    Show Alert {index + 1}
                  </Button>
                ))}
              </div>
              <div>
                <Button className="m-1" onClick={handleNotificationAlert}>
                  {" "}
                  notifications{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Index;

import { useState, useEffect } from "react";
import {
  CheckIcon,
  Combobox,
  Group,
  Pill,
  PillsInput,
  useCombobox,
} from "@mantine/core";
import { Label } from "reactstrap";
import Icon from "../../icon";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

const MultipleSelectCreatableInput = ({
  label,
  required,
  subtitle,
  clearable,
  hasCloseOnOption,
  placeholder,
  // searchable,
  // value,
  options,
  onChange,
}) => {
  const { t } = useTranslation();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });
  const [search, setSearch] = useState("");
  const [data, setData] = useState(options);
  const [currentValue, setCurrentValue] = useState([]);
  const exactOptionMatch = data.some((item) => item === search);

  useEffect(() => {
    onChange(currentValue);
  }, [currentValue]);

  const handleValueSelect = (val) => {
    setSearch("");
    const findObject = data.find((item) => item.value === val);

    if (val === "$create") {
      setData((current) => [...current, { value: search, label: search }]);
      setCurrentValue((current) => [...current, search]);
    } else {
      setCurrentValue((current) =>
        current.includes(findObject?.value)
          ? current.filter((v) => v !== findObject?.value)
          : [...current, findObject.value]
      );
    }
  };

  const handleValueRemove = (val) => {
    setCurrentValue((current) => current.filter((v) => v !== val));
  };

  const values = currentValue.map((item, index) => (
    <Pill
      key={index}
      onRemove={() => handleValueRemove(item)}
      withRemoveButton={hasCloseOnOption ? true : false}
      className={
        hasCloseOnOption
          ? "multiple-select-pill-with-x"
          : "multiple-select-pill"
      }
    >
      {item}
    </Pill>
  ));

  const currentOptions = data
    .filter((item) =>
      item.label.toLowerCase().includes(search.trim().toLowerCase())
    )
    .filter((item) => {
      return !currentValue.includes(item.value);
    });

  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
        position="bottom"
        middlewares={{ flip: false, shift: false }}
      >
        <Combobox.DropdownTarget>
          <PillsInput
            onClick={() => combobox.openDropdown()}
            rightSectionPointerEvents={currentValue.length > 0 ? "none" : "all"}
            rightSection={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 24,
                }}
              >
                {clearable && currentValue !== null && (
                  <div
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => setCurrentValue([])}
                    aria-label="Clear value"
                  >
                    <Icon
                      className="comboBox-close-icon"
                      style={{ marginRight: 10 }}
                      icon="closeIcon"
                      size={[18, 18]}
                    />
                  </div>
                )}
                <Icon icon="downArrowIcon" size={[11, 7]} />
              </div>
            }
          >
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={placeholder}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(currentValue[currentValue.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <SimpleBar style={{ maxHeight: "320px" }} autoHide={false}>
            <Combobox.Options>
              {currentOptions?.map((item, index) => (
                <Combobox.Option
                  value={item.value}
                  key={index}
                  active={
                    currentOptions.length > 0 && index === 0 ? true : false
                  }
                  selected={
                    currentOptions.length > 0 && index === 0 ? true : false
                  }
                >
                  <Group gap="sm">
                    {currentValue.includes(item) ? (
                      <CheckIcon size={12} />
                    ) : null}
                    <span>{item.label}</span>
                  </Group>
                </Combobox.Option>
              ))}

              {!exactOptionMatch && search.trim().length > 0 && (
                <Combobox.Option value="$create" selected={true}>
                  + {t("CREATE")} {search}
                </Combobox.Option>
              )}

              {exactOptionMatch &&
                search.trim().length > 0 &&
                currentOptions.length === 0 && (
                  <Combobox.Empty>{t("NO_RESULTS_FOUND")}</Combobox.Empty>
                )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox.Dropdown>
      </Combobox>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default MultipleSelectCreatableInput;

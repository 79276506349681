import React, { useEffect, useState } from "react";
import { Combobox, Input, InputBase, useCombobox } from "@mantine/core";
import Icon from "../../icon";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

const SelectInput = ({
  required,
  name,
  subtitle,
  label,
  options,
  placeholder,
  searchable,
  onChange,
  value,
  id,
  disabled,
  clearable,
  small,
  openTop,
  dataKey = "id",
  dataLabel = "name",
  languageId = 1,
  useSimpleOptions = false,
  clearFuntion
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentValue, setCurrentValue] = useState(value || null);
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },
    onDropdownOpen: () => {
      searchable && combobox.focusSearchInput();
    },
  });

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleLabel = (item) => {
    if (!item) return "";
    if (useSimpleOptions) return item.label;

    const fields = dataLabel.split(".");
    let result = item;
    for (let field of fields) {
      result = result[field];
      if (result === undefined) break;
    }
    if (Array.isArray(result)) {
      result = result.find(
        (translation) => translation.languageId === languageId
      );
      result = result ? result.name : "";
    }
    return result || "";
  };

  const optionsArray = options
    .filter((item) =>
      handleLabel(item)?.toLowerCase().includes(search.toLowerCase().trim())
    )
    .map((item, index) => (
      <Combobox.Option
        value={useSimpleOptions ? item.value : item[dataKey]}
        key={index}
        active={index === 0}
        selected={index === 0}
      >
        {handleLabel(item)}
      </Combobox.Option>
    ));

  const handleDisplayValue = () => {
    const selectedItem = options.find((item) =>
      useSimpleOptions
        ? item.value === currentValue
        : item[dataKey] === currentValue
    );
    return selectedItem ? handleLabel(selectedItem) : null;
  };

  return (
    <div>
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Combobox
        store={combobox}
        resetSelectionOnOptionHover
        withinPortal={false}
        onOptionSubmit={(val) => {
          setCurrentValue(val);
          onChange(val);
          combobox.closeDropdown();
          combobox.updateSelectedOptionIndex("active");
        }}
        position={openTop ? "top" : "bottom"}
        middlewares={{ flip: false, shift: false }}

        className={useSimpleOptions && "simpleOption"}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            pointer
            onClick={() => {
              combobox.toggleDropdown();
            }}
            name={name}
            disabled={disabled}
            id={id}
            data={optionsArray}
            rightSection={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: small ? 0 : 24,
                  cursor: "pointer"
                }}
              >
                {clearable && currentValue && (
                  <div
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => {
                      useSimpleOptions && clearFuntion();
                      setCurrentValue(null);
                    }}
                    aria-label="Clear value"
                  >
                    <Icon
                      className="comboBox-close-icon"
                      style={{ marginRight: 10 }}
                      icon="closeIcon"
                      size={[18, 18]}
                    />
                  </div>
                )}
                <Icon onClick={() => combobox.toggleDropdown()} icon="downArrowIcon" size={[11, 7]} />
              </div>
            }
          >
            {handleDisplayValue() || (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>
        <Combobox.Dropdown style={{ left: "unset !important" }}>
          {searchable && (
            <Combobox.Search
              rightSectionPointerEvents={value === null ? "none" : "all"}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder={`${t("SEARCH")}...`}
              rightSection={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 24,
                  }}
                >
                  {search !== "" ? (
                    <div
                      onClick={() => setSearch("")}
                      aria-label="Clear value"
                    >
                      <Icon
                        className="comboBox-close-icon"
                        icon="closeIcon"
                        size={[18, 18]}
                      />
                    </div>
                  ) : (
                    <Icon icon="searchIcon" size={[15, 15]} />
                  )}
                </div>
              }
            />
          )}
          <SimpleBar style={{ maxHeight: "320px" }} autoHide={false}>
            <Combobox.Options style={{ width: useSimpleOptions ? "100%" : "max-content" }}>
              {optionsArray.length > 0 ? (
                optionsArray
              ) : (
                <Combobox.Empty>{t("NO_RESULTS_FOUND")}</Combobox.Empty>
              )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox.Dropdown>
      </Combobox>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default SelectInput;
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Icon from "../../../../Components/Common/icon";
import TextInput from "../../../../Components/Common/Inputs/TextInput/TextInput";
import { Accordion } from "@mantine/core";
import SimpleBar from "simplebar-react";
import classnames from "classnames";
import { Checkbox } from "@mantine/core";

const Filters = ({
  segments,
  gender,
  categories,
  entities,
  filters,
  setFilters,
  setColumnFilters,
  columnFilters,
  manufactoring,
  tab,
}) => {
  const { t } = useTranslation();
  const [displayedEntities, setDisplayedEntities] = useState();
  const [search, setSearch] = useState();
  const [entitiesSearch, setEntitiesSearch] = useState();
  const [value, setValue] = useState(); // Add [] if multiple tabs are allowed
  const [segmentTabs, setSegmentTabs] = useState(); // Add [] if multiple tabs are allowed
  const [genderTabs, setGenderTabs] = useState(); // Add [] if multiple tabs are allowed
  const [categoriesTabs, setCategoriesTabs] = useState(); // Add [] if multiple tabs are allowed
  const [filtersSize, setFiltersSize] = useState(0);
  const [showAllEntities, setShowAllEntities] = useState(false);
  const labelsRef = useRef(null);

  useEffect(() => {
    setValue([]);
  }, [tab]);

  useEffect(() => {
    setDisplayedEntities(entities);
  }, [entities]);

  const handleTabs = (event) => {
    setValue(event);

    if (event?.includes("event")) {
      setGenderTabs([]);
      setCategoriesTabs([]);
      setShowAllEntities(false);
    }

    if (event?.includes("gender")) {
      setSegmentTabs("");
      setCategoriesTabs("");
      setShowAllEntities(false);
    }

    if (event?.includes("categories")) {
      setSegmentTabs("");
      setGenderTabs([]);
      setShowAllEntities(false);
    }

    if (event?.includes("entities")) {
      setSegmentTabs("");
      setGenderTabs([]);
      setCategoriesTabs([]);
      setShowAllEntities(false);
    }
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      if (!filters.search.find((item) => item.value === search)) {
        // setFilters({
        //   ...filters,
        //   search: [...filters.search, { label: search, value: search }],
        // });
        setFilters({
          ...filters,
          search: [{ label: search, value: search }],
        });
      }
      setSearch();
    }
  };

  const handleEntitiesSearch = (event) => {
    if (event.key === "Enter") {
      if (entitiesSearch !== undefined || entitiesSearch !== "") {
        const result = entities?.filter((item) => {
          const normalizedSearch = entitiesSearch?.toLowerCase();
          const normalizedItem = item?.label?.toLowerCase();
          return normalizedItem?.includes(normalizedSearch);
        });
        if (result.length > 0) {
          setDisplayedEntities(result);
        } else {
          setDisplayedEntities(entities);
        }
      } else {
        setDisplayedEntities(entities);
      }
    }
  };

  const handleEntitiesCheck = (entity) => {
    // if (filters.entities.find((item) => item.value === entity.value)) {
    //   const remove = filters.entities.filter(
    //     (item) => item.value !== entity.value
    //   );
    //   setFilters({
    //     ...filters,
    //     entities: remove,
    //   });
    // } else {
    //   setFilters({
    //     ...filters,
    //     entities: [
    //       ...filters.entities,
    //       { value: entity.value, label: entity.label },
    //     ],
    //   });
    // }

    if (filters.entities.find((item) => item.value === entity.value)) {
      const remove = filters.entities.filter(
        (item) => item.value !== entity.value
      );
      setFilters({
        ...filters,
        entities: remove,
      });
    } else {
      setFilters({
        ...filters,
        entities: [{ value: entity.value, label: entity.label }],
      });
    }
  };

  const handleSegment = (option) => {
    if (filters.segment.find((item) => item.value === option.value)) {
      setFilters({
        ...filters,
        segment: filters.segment.filter((item) => item.value !== option.value),
      });
    } else {
      setFilters({ ...filters, segment: [option] });
    }

    // if (filters.segment.find((item) => item.value === option.value)) {
    //   setFilters({
    //     ...filters,
    //     segment: filters.segment.filter((item) => item.value !== option.value),
    //   });
    // } else {
    //   setFilters({ ...filters, segment: [...filters.segment, option] });
    // }
  };

  const handleGender = (option) => {
    // if (filters.gender.find((item) => item.value === option.value)) {
    //   setFilters({
    //     ...filters,
    //     gender: filters.gender.filter((item) => item.value !== option.value),
    //   });
    // } else {
    //   setFilters({ ...filters, gender: [...filters.gender, option] });
    // }

    if (filters.gender.find((item) => item.value === option.value)) {
      setFilters({
        ...filters,
        gender: filters.gender.filter((item) => item.value !== option.value),
      });
    } else {
      setFilters({ ...filters, gender: [option] });
    }
  };

  const handleCategories = (option, value) => {
    // if (filters.categories.find((item) => item.value === option.value)) {
    //   setFilters({
    //     ...filters,
    //     categories: filters.categories.filter(
    //       (item) => item.value !== option.value
    //     ),
    //   });
    // } else {
    //   setFilters({ ...filters, categories: [...filters.categories, option] });
    // }

    if (filters.categories.find((item) => item.value === option.value)) {
      setFilters({
        ...filters,
        categories: filters.categories.filter(
          (item) => item.value !== option.value
        ),
      });
    } else {
      setFilters({
        ...filters,
        categories: [
          { value: option.value, label: option.label, mainCategory: value },
        ],
      });
    }
  };

  const handleClearAll = () => {

    setColumnFilters({
      manufactoring: "",
    });
    setFilters({
      search: [],
      segment: [],
      gender: [],
      categories: [],
      entities: [],
    });
    setValue([]);
    setSearch("");
    setSegmentTabs("");
    setGenderTabs([]);
    setCategoriesTabs([]);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.addEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setFiltersSize(
      window.innerHeight -
        533 -
        labelsRef?.current?.getBoundingClientRect()?.height || 0
    );
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [filters]);

  return (
    <div
      className="products-filter-wrapper"
      style={{
        maxHeight: "calc(100vh - 185px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="bodyText_2">{t("FILTERS")}</div>
        {/* Clear all */}
        <div
          className="heading_5 text-info text-decoration-underline cursor-pointer"
          onClick={handleClearAll}
        >
          {t("CLEAR_ALL")}
        </div>
      </div>
      {/* Lables */}
      <div className="filter-labels-wrapper" ref={labelsRef}>
        {filters.search?.map((filter, index) => (
          <div className="d-inline-block label-gap" key={index}>
            <div className="filter-label">
              <div className="bodyText_11 text-white">{filter.label}</div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      search: filters.search.filter(
                        (item) => item.label !== filter.label
                      ),
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {filters.segment?.map((filter, index) => (
          <div className="d-inline-block label-gap" key={index}>
            <div className="filter-label">
              <div className="bodyText_11 text-white">{filter.label}</div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      segment: filters.segment.filter(
                        (item) => item.label !== filter.label
                      ),
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {filters.gender?.map((filter, index) => (
          <div className="d-inline-block label-gap" key={index}>
            <div className="filter-label">
              <div className="bodyText_11 text-white">{filter.label}</div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      gender: filters.gender.filter(
                        (item) => item.label !== filter.label
                      ),
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {filters.categories?.map((filter, index) => (
          <div className="d-inline-block label-gap" key={index}>
            <div className="filter-label">
              <div className="bodyText_11 text-white">{filter.label}</div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      categories: filters.categories.filter(
                        (item) => item.label !== filter.label
                      ),
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {filters.entities?.map((filter, index) => (
          <div className="d-inline-block label-gap" key={index}>
            <div className="filter-label">
              <div className="bodyText_11 text-white">{filter.label}</div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setFilters({
                      ...filters,
                      entities: filters.entities.filter(
                        (item) => item.label !== filter.label
                      ),
                    });
                  }}
                />
              </div>
            </div>
          </div>
        ))}
        {columnFilters.manufactoring !== "" && (
          <div className="d-inline-block label-gap">
            <div className="filter-label">
              <div className="bodyText_11 text-white">
                {
                  manufactoring?.filter(
                    (e) => e?.value === columnFilters?.manufactoring
                  )[0]?.label
                }
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center">
                <div className="labels-divider-vertical"></div>
                <Icon
                  style={{ cursor: "pointer" }}
                  icon="closeIcon"
                  size={[16, 16]}
                  color="white"
                  onClick={() => {
                    setColumnFilters({
                      manufactoring: "",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="divider" />
      {/* Search */}
      <TextInput
        placeholder={`${t("SEARCH_ARTICLES")}...`}
        type="text"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
        onKeyDown={handleSearch}
        value={search}
        icon="searchIcon"
        iconSize={[14, 14]}
        noBorder
      />
      <div className="divider" />
      {segments.length > 0 &&
      gender.length > 0 &&
      categories.length > 0 &&
      entities.length > 0 ? (
        <Accordion value={value} onChange={(e) => handleTabs(e)}>
          {/* Segment */}
          {segments && (
            <>
              <Accordion.Item value="segment">
                <Accordion.Control disabled={tab !== 1}>
                  <div
                    className="heading_3 text-accordion-label d-flex flex-direction-row"
                    style={{ color: "rgba(68, 79, 89, 0.60)" }}
                  >
                    {t("SEGMENT")}{" "}
                    {filters?.segment?.length > 0 && (
                      <div className="filters-number-indication bodyText_12 text-white">
                        {filters?.segment?.length}
                      </div>
                    )}
                  </div>
                </Accordion.Control>
                <Accordion.Panel>
                  <SimpleBar
                    autoHide={false}
                    id="scrollbar"
                    style={{
                      maxHeight: filtersSize,
                      marginRight: 2.5,
                      marginTop: 16,
                    }}
                  >
                    {segments &&
                      segments?.map((segment, index) => (
                        <Accordion
                          value={segmentTabs}
                          onChange={setSegmentTabs}
                          style={{ marginBottom: 13, marginRight: 15 }}
                          key={index}
                          disableChevronRotation
                          chevron={
                            <>
                              {/* <div className="bodyText_12 text-info chevron-option-wrapper">
                        {segment.options.length}
                      </div> */}
                            </>
                          }
                        >
                          <Accordion.Item value={segment.label}>
                            <Accordion.Control>
                              <div
                                className={classnames(
                                  "heading_5 product-filter-main-item-text",
                                  {
                                    active: filters.segment.find(
                                      (item) => item.value === segment.value
                                    ),
                                  }
                                )}
                                onClick={() => handleSegment(segment)}
                              >
                                {segment.label}
                              </div>
                            </Accordion.Control>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                  </SimpleBar>
                </Accordion.Panel>
              </Accordion.Item>
              <div className="divider" />
            </>
          )}
          {/* Gender */}
          {gender && (
            <>
              <Accordion.Item value="gender">
                <Accordion.Control disabled={tab === 2 || tab === 3}>
                  <div
                    className="heading_3 text-accordion-label d-flex flex-direction-row"
                    style={{ color: "rgba(68, 79, 89, 0.60)" }}
                  >
                    {t("GENDER")}{" "}
                    {filters?.gender?.length > 0 && (
                      <div className="filters-number-indication bodyText_12 text-white">
                        {filters?.gender?.length}
                      </div>
                    )}
                  </div>
                </Accordion.Control>
                <Accordion.Panel>
                  <SimpleBar
                    autoHide={false}
                    id="scrollbar"
                    style={{
                      maxHeight: filtersSize,
                      marginRight: 2.5,
                      marginTop: 16,
                    }}
                  >
                    {gender &&
                      gender?.map((gender, index) => (
                        <Accordion
                          value={genderTabs}
                          onChange={setGenderTabs}
                          style={{ marginBottom: 13, marginRight: 15 }}
                          key={index}
                          disableChevronRotation
                          chevron={
                            <>
                              {/* <div className="bodyText_12 text-info chevron-option-wrapper">
                        {gender.options.length}
                      </div> */}
                            </>
                          }
                        >
                          <Accordion.Item value={gender.label}>
                            <Accordion.Control>
                              <div
                                className={classnames(
                                  "heading_5 product-filter-main-item-text",
                                  {
                                    active: filters.gender.find(
                                      (item) => item.value === gender.value
                                    ),
                                  }
                                )}
                                onClick={() => handleGender(gender)}
                              >
                                {gender.label}
                              </div>
                            </Accordion.Control>
                          </Accordion.Item>
                        </Accordion>
                      ))}
                  </SimpleBar>
                </Accordion.Panel>
              </Accordion.Item>
              <div className="divider" />
            </>
          )}
          {/* Categories */}
          {categories && (
            <>
              <Accordion.Item value="categories">
                <Accordion.Control disabled={tab === 2 || tab === 3}>
                  <div
                    className="heading_3 text-accordion-label d-flex flex-direction-row"
                    style={{ color: "rgba(68, 79, 89, 0.60)" }}
                  >
                    {t("CATEGORIES")}{" "}
                    {filters?.categories?.length > 0 && (
                      <div className="filters-number-indication bodyText_12 text-white">
                        {filters?.categories?.length}
                      </div>
                    )}
                  </div>
                </Accordion.Control>
                <Accordion.Panel>
                  <SimpleBar
                    autoHide={false}
                    id="scrollbar"
                    style={{
                      maxHeight: filtersSize,
                      marginRight: 2.5,
                      marginTop: 16,
                    }}
                  >
                    {categories &&
                      categories?.map((category, index) => (
                        <Accordion
                          value={categoriesTabs}
                          onChange={setCategoriesTabs}
                          style={{ marginBottom: 13, marginRight: 15 }}
                          key={index}
                          disableChevronRotation
                          chevron={
                            <>
                              {/* <div className="bodyText_12 text-info chevron-option-wrapper">
                        {category.options.length}
                      </div> */}
                            </>
                          }
                        >
                          {category.options.length > 0 ? (
                            <Accordion.Item value={category.label}>
                              <Accordion.Control>
                                <div className="heading_5">
                                  {category.label}
                                </div>
                              </Accordion.Control>
                              <Accordion.Panel style={{ marginTop: 13 }}>
                                {category.options.map((option, index) => (
                                  <div
                                    key={index}
                                    className={classnames(
                                      "heading_6 product-filter-item-text",
                                      {
                                        active: filters.categories.find(
                                          (item) => item.value === option.value
                                        ),
                                      }
                                    )}
                                    onClick={() =>
                                      // TODO
                                      handleCategories(option, category.value)
                                    }
                                  >
                                    {option.label}
                                  </div>
                                ))}
                              </Accordion.Panel>
                            </Accordion.Item>
                          ) : (
                            <Accordion.Item value={category.label}>
                              <Accordion.Control>
                                <div
                                  className={classnames(
                                    "heading_5 product-filter-main-item-text",
                                    {
                                      active: filters.categories.find(
                                        (item) => item.value === category.value
                                      ),
                                    }
                                  )}
                                  onClick={() => handleCategories(category)}
                                >
                                  {category.label}
                                </div>
                              </Accordion.Control>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      ))}
                  </SimpleBar>
                </Accordion.Panel>
              </Accordion.Item>
              <div className="divider" />
            </>
          )}
          {/* Categories */}
          {entities && (
            <Accordion.Item value="entities" style={{ overflowY: "hidden" }}>
              <Accordion.Control disabled={tab === 2 || tab === 3}>
                <div
                  className="heading_3 text-accordion-label d-flex flex-direction-row"
                  style={{ color: "rgba(68, 79, 89, 0.60)" }}
                >
                  {t("ENTITIES")}{" "}
                  {filters?.entities?.length > 0 && (
                    <div className="filters-number-indication bodyText_12 text-white">
                      {filters?.entities?.length}
                    </div>
                  )}
                </div>
              </Accordion.Control>
              <Accordion.Panel>
                <div style={{ marginBottom: 18 }}>
                  <TextInput
                    placeholder={`${t("SEARCH_YOUR_ENTITIES")}...`}
                    type="text"
                    onChange={(e) => {
                      setEntitiesSearch(e.target.value);
                    }}
                    onKeyDown={handleEntitiesSearch}
                    value={entitiesSearch}
                    icon="searchIcon"
                    iconSize={[14, 14]}
                    noBorder
                  />
                </div>
                <div>
                  {displayedEntities
                    ?.slice(0, showAllEntities ? displayedEntities?.length : 4)
                    ?.map((entity, index) => (
                      <Checkbox
                        style={{ marginBottom: 10 }}
                        label={entity.label}
                        key={index}
                        checked={
                          filters?.entities?.find(
                            (item) => item?.value === entity?.value
                          ) || false
                        }
                        onChange={() => handleEntitiesCheck(entity)}
                      />
                    ))}
                </div>
                {displayedEntities?.length > 4 && (
                  <p
                    className="heading_4 text-success cursor-pointer"
                    onClick={() => setShowAllEntities(!showAllEntities)}
                  >
                    {!showAllEntities ? displayedEntities?.length : ""}
                    <span className="heading_6 text-success">
                      {showAllEntities ? "View Less" : "More Results"}
                    </span>
                  </p>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          )}
        </Accordion>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-info d-flex justify-content-center align-items-center"
            role="status"
          />
        </div>
      )}
    </div>
  );
};

export default Filters;

import client from "../config/AxiosConfig";

export const GetB2BClients = async (page, pageSize, search) =>
  client.get(
    `/B2bclient/ListClients?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  );

export const GetB2BClient = async (id) =>
  client.get(`/B2bclient/getB2bclient/${id}`);

export const GetB2BClientWorkgroups = async (id, page, pageSize, search) =>
  client.get(
    `/B2bclient/${id}/ListPositions?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  );

export const GetB2BClientEmployees = async (id, page, pageSize, search) =>
  client.get(
    `/B2bclient/${id}/ListEmployees?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  );

export const GetB2BClientUsers = async (id, page, pageSize, search) =>
  client.get(
    `/B2bclient/${id}/ListUsers?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  );

export const GetB2BClientProfiles = async (id, page, pageSize, search) =>
  client.get(
    `/B2bclient/${id}/ActivateProfiles?ItemsPerPage=${pageSize}&Page=${page}&Search=${search}`
  );

/** *************************** WORKGROUPS ********************************* */

export const GetWorkgroup = async (id) =>
  client.get(`/Positions/PositionViewDetail/${id}`);

export const CreateWorkgroup = async (clientId, data) =>
  client.post(`/Positions/${clientId}/createNewPosition`, data);

export const EditWorkgroupInformation = async (id, data) =>
  client.put(`/Positions/PositionViewDetail/${id}/Information`, data);

export const EditWorkgroupModulation = async (id, data) =>
  client.put(`/Positions/PositionViewDetail/${id}/Modulations`, data);

export const EditWorkgroupED = async (id, data) =>
  client.put(`/Positions/PositionViewDetail/${id}/ED`, data);

export const EditWorkgroupRelatedProducts = async (id, data) =>
  client.put(`/Positions/PositionViewDetail/${id}/RelatedProducts`, data);

export const EditWorkgroupImage = async (id, data) =>
  client.put(`/Positions/putPositionImage/${id}`, data);

export const GetB2bclientWorkgroupsDrop = async () =>
  client.get("/B2bclient/getB2bclientWorkgroupsDrop");

export const GetB2bclientAddressDrop = async (id) =>
  client.get(`/Employees/GetClientAddressDrop/${id}`);

/** *************************** EMPLOYEES ********************************* */

export const GetEmployee = async (id) =>
  client.get(`/Employees/EmployeeViewDetail/${id}`);

export const CreateEmployee = async (clientId, data) =>
  client.post(`/Employees/${clientId}/createNewEmployee`, data);

export const EditEmployeeInformation = async (id, data) =>
  client.put(`/Employees/EmployeeViewDetail/${id}/Information`, data);

export const EditEmployeeAddresses = async (id, data) =>
  client.put(`/Employees/EmployeeViewDetail/${id}/Address`, data);

export const EditEmployeePlan = async (id, data) =>
  client.put(`/Employees/EmployeeViewDetail/${id}/EmployeePlan`, data);

export const CreateEmployeeExtraCredits = async (id, data) =>
  client.post(`/Employees/${id}/createNewExtraCredit`, data);

export const DeleteEmployeeExtraCredits = async (id, creditId) =>
  client.delete(`/Employees/${id}/deleteExtraCredits/${creditId}`);

/** *************************** USERS ********************************* */

export const GetUser = async (id) =>
  client.get(`/AspnetUsers/UserViewDetail/${id}`);

export const CreateUser = async (clientId, data) =>
  client.post(`/AspnetUsers/${clientId}/createNewUser`, data);

export const EditUser = async (id, data) =>
  client.put(`/AspnetUsers/PutUserViewDetail/${id}`, data);

export const GetProfiles = async (id) =>
  client.get(`/B2bclient/${id}/ListProfiles`);

export const ChangeUserPassword = async (id, data) =>
  client.put(`/AspnetUsers/${id}/SetPassword`, data);

/** *************************** PROFILES ********************************* */

export const GetProfile = async (id) =>
  client.get(`/Profiles/${id}/ProfileViewDetail`);

export const GetProfileUsers = async (id, page, pageSize) =>
  client.get(
    `/Profiles/${id}/Users?ItemsPerPage=${pageSize}&Page=${page}`
  );

export const GetProfilePermissions = async (id) =>
  client.get(`/Profiles/${id}/ListPermissions`);

export const SaveProfilePermissions = async (id, data) =>
  client.put(`/Profiles/${id}/PutPermissions`, data);

export const CreateProfile = async (data) =>
  client.post("/Profiles/CreateNewProfile", data);

export const UpdateProfile = async (id, data) =>
  client.put(`/Profiles/UpdateProfile/${id}`, data);

export const GetProfilesDrop = async () =>
  client.get("/Profiles/GetProfilesDrop");

/** *************************** IMPORTS ********************************* */

export const ImportEmployees = async (id, data) =>
  client.put(`/SkyproConfig/ImportEmployees/${id}`, data);

export const ImportEmployeeFitting = async (id, data) =>
  client.put(`/SkyproConfig/ImportEmployeeFitting/${id}`, data);

export const ImportEmployeeCredits = async (id, data) =>
  client.put(`/SkyproConfig/ImportEmployeeCredits/${id}`, data);

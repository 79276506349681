import React from "react";
import { Button, Spinner, UncontrolledTooltip } from "reactstrap";
import Icon from "../icon";

const BaseButton = ({
  text,
  height,
  type,
  disabled,
  color,
  width,
  icon,
  iconPosition = "left",
  loading,
  tooltip,
  tolltipPosition,
  id,
  counter,
  counterColor,
  alert,
  alertCounter,
  onClick,
  htmlType,
}) => {
  // Id mandatory for tooltip usage

  const handleHeight = () => {
    if (height === "small") {
      return "btn-h-small";
    }
    return "";
  };

  const handleType = () => {
    if (type === "secondary") {
      return "base-btn-secondary";
    }
    return "base-btn-default";
  };

  const handleDisabled = () => {
    if (disabled) {
      return "base-btn-disabled";
    }
    return "";
  };

  const handleColor = () => {
    if (color === "red") {
      return "red";
    } else if (color === "grey") {
      return "grey";
    }

    return "";
  };

  const handleWidth = () => {
    switch (width) {
      case "small":
        return "btn-w-small";
      case "medium":
        return "btn-w-medium";
      case "large":
        return "btn-w-large";
      case "full":
        return "w-100";
      default:
        return "";
    }
  };

  const handleIcon = () => {
    if (icon) {
      return "base-btn-icon";
    }
    return "";
  };

  return (
    <>
      <Button
        style={{ position: "relative" }}
        id={"Tooltip-" + id}
        disabled={disabled}
        onClick={onClick}
        type={htmlType || "button"}
        className={`
          ${handleDisabled()} 
          ${handleType()} 
          ${handleHeight()} 
          ${handleColor()} 
          ${handleWidth()} 
          ${handleIcon()}
        `}
      >
        {alertCounter && (
          <div className="base-btn-alert-counter">{alertCounter}</div>
        )}
        {alert && <div className="base-btn-alert" />}
        {loading ? (
          <span className="d-flex align-items-center">
            <Spinner size="sm" className="flex-shrink-0">
              Loading...
            </Spinner>
            <span className="flex-grow-1 ms-2">Loading...</span>
          </span>
        ) : (
          <>
            {icon && iconPosition === "left" && (
              <Icon style={{ marginRight: 6 }} icon={icon} size={[16, 16]} />
            )}
            {text}
            {icon && iconPosition === "right" && (
              <Icon style={{ marginLeft: 6 }} icon={icon} size={[16, 16]} />
            )}
            {counter && (
              <div
                style={{
                  backgroundColor: counterColor ? counterColor : "black",
                }}
                className="base-button-counter"
              >
                {counter}
              </div>
            )}
          </>
        )}
      </Button>
      {tooltip && id && (
        <UncontrolledTooltip
          className="base-tooltip"
          placement={tolltipPosition ? tolltipPosition : "top"}
          target={"Tooltip-" + id}
          style={{
            backgroundColor: "#444F59",
            marginBottom: tolltipPosition === "bottom" ? 0 : 10,
            marginTop: tolltipPosition !== "bottom" ? 0 : 10,
          }}
        >
          {tooltip}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default BaseButton;

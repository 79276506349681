import React from "react";
import { Label } from "reactstrap";
import { DatePickerInput } from "@mantine/dates";
import Icon from "../../icon";

const DateInput = ({
  label,
  required,
  subtitle,
  value,
  onChange,
  name,
  id,
  placeholder,
  type,
  clearable,
  clearFuntion
}) => {
  return (
    <div>
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <div className="dateinput-container ">
        <DatePickerInput
          placeholder={placeholder}
          name={name}
          id={id}
          value={value}
          onChange={onChange}
          type={type}
          withCellSpacing={false}
          valueFormat="DD-MM-YYYY"
          size="md"
        />
        {clearable && value.length > 0 && (
          <div
            className="dateinput-close-icon"
            onClick={() => {
              clearFuntion();
            }}
            aria-label="Clear value"
          >
            <Icon
              className="comboBox-close-icon"
              style={{ marginRight: 5 }}
              icon="closeIcon"
              size={[18, 18]}
            />
          </div>
        )}
      </div>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default DateInput;

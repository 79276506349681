import React from "react";
import { Link } from "react-router-dom";
import Icon from "../icon";

const BaseLink = ({ text, link, icon, disabled }) => {
  return (
    <div>
      <Link
        isActive={disabled}
        to={disabled ? "#" : link}
        style={{ cursor: disabled ? "default" : "pointer" }}
        className={`${disabled ? "disabled" : ""} base-link`}
      >
        <div style={{ display: "flex" }}>
          <Icon icon={icon} size={[12, 12]} style={{ marginRight: 3 }} />
          <div className="base-icon-text" style={{ width: "auto" }}>
            {text}
            <div
              style={{
                width: "auto",
                height: 1,
                backgroundColor: "#64A0E3",
                marginTop: 0.5,
              }}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BaseLink;

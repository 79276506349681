import React from "react";
import { DatePicker } from "@mantine/dates";

const DatePickerComponent = ({ value, onChange }) => {
  return (
    <DatePicker
      type="range"
      value={value}
      onChange={(date_range) => {
        onChange(date_range);
      }}
    />
  );
};

export default DatePickerComponent;

import Breadcrumb from "../../Components/Common/Breadcrumb";
import Global from "./Global";
import "./styles.scss";

// Ready for Tab Implementations
// const TABS = [
//   { id: 1, title: "Global" },
//   { id: 2, title: "SKU" },
//   { id: 3, title: "Smart Order" },
// ];

const breadcrumbData = {
  pageTitle: "Smart Orders",
  items: [{ title: "Orders", url: "/smartOrders" }],
};

const Index = () => {
  // Required for tab implementation
  // const [activeTab, setActiveTab] = useState(1);
  const activeTab = 1;
  return (
    <div className="page-content">
      <Breadcrumb breadcrumbData={breadcrumbData} />
      {/* Ready for tab implementation */}
      {/* <UiTabs tab={activeTab} setTab={setActiveTab} options={TABS} /> */}
      <div className="smart-center-content-wrapper">
        {activeTab === 1 && <Global />}
        {/* {activeTab === 2 && <SKU />}
        {activeTab === 3 && <SmartOrder />} */}
      </div>
    </div>
  );
};

export default Index;

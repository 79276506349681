import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// Define a functional component called Navdata
const Navdata = () => {
  // Initialize history variable using useNavigate hook
  const location = useLocation();
  const urlQueryParams = new URLSearchParams(location.search);
  const urlParam = urlQueryParams.get("url");
  const hash = location?.hash.slice(1);

  // Define state variable and its setter using useState hook
  const [iscurrentState, setIscurrentState] = useState(
    urlParam ? urlParam : ""
  );

  const [iscurrentSubItemState, setIscurrentSubItemState] = useState(
    hash ? hash : ""
  );

  const [iscurrentChildItemState, setIscurrentChildItemState] = useState("");

  const [items, setItems] = useState({
    items: [],
    currentState: iscurrentState,
    subItemCurrentState: iscurrentSubItemState,
    childItemCurrentState: iscurrentChildItemState,
  });

  // Function to update sidebar icon
  function updateIconSidebar(e) {
    // Check if event object, its target, and 'subitems' attribute exist
    if (e && e.target && e.target.getAttribute("subitems")) {
      // Find the unordered list element with id 'two-column-menu'
      const ul = document.getElementById("two-column-menu");
      // Find all elements with class 'nav-icon' and 'active' within the unordered list
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      // Convert the NodeList to an array
      let activeIconItems = [...iconItems];
      // Loop through active icon items and remove 'active' class
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        // Get the value of 'subitems' attribute
        var id = item.getAttribute("subitems");
        // Check if element with id exists, then remove 'show' class
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("Cockpit");
      });
    }
  }

  const ApiResponse = [
    {
      label: "MENU",
      isHeader: true,
    },
    {
      id: "cockpit",
      label: "COCKPIT",
      icon: "ri-dashboard-2-line",
      link: "/iframe?url=#",
    },
    {
      id: "orders",
      label: "ORDERS",
      costumIcon: "orderIcon",
      icon: "ri-apps-2-line",
      subItems: [
        {
          id: "b2b",
          label: "B2B",
          link: "/iframe?url=orders#b2b",
          parentId: "orders",
        },
        {
          id: "b2c",
          label: "B2C",
          link: "/iframe?url=orders#b2c",
          parentId: "orders",
        },
        {
          id: "b2b2c",
          label: "B2B2C",
          link: "/iframe?url=orders#b2b2c",
          parentId: "orders",
        },
        {
          id: "smartOrders",
          label: "Smart Orders",
          link: "/smartOrders",
          parentId: "orders",
        },
      ],
    },
    {
      id: "suppliers",
      label: "SUPPLIERS",
      icon: "las la-cubes",
      subItems: [
        {
          id: "open",
          label: "OPEN_PO",
          link: "/iframe?url=suppliers#open",
          parentId: "suppliers",
        },
        {
          id: "process",
          label: "PROCESSING_PO",
          link: "/iframe?url=suppliers#process",
          parentId: "suppliers",
        },
        {
          id: "so",
          label: "SUPPLIERS_ORDERS",
          link: "/iframe?url=suppliers#so",
          parentId: "suppliers",
        },
        {
          id: "suppliers",
          label: "SUPPLIERS",
          link: "/iframe?url=suppliers#suppliers",
          parentId: "suppliers",
        },
      ],
    },
    {
      id: "products",
      label: "ARTICLES",
      costumIcon: "productsIcon",
      icon: "bx bx-cube",
      subItems: [
        {
          id: "b2b",
          label: "B2B",
          link: "/iframe?url=products#b2b",
          parentId: "products",
        },
        {
          id: "b2c",
          label: "B2C",
          link: "/iframe?url=products#b2c",
          parentId: "products",
        },
        {
          id: "materials",
          label: "RAW_MATERIALS",
          link: "/iframe?url=products#materials",
          parentId: "products",
        },
        {
          id: "services",
          label: "SERVICES",
          link: "/iframe?url=products#services",
          parentId: "products",
        },
      ],
    },
    {
      id: "fitting",
      label: "FITTING",
      icon: "ri-pencil-ruler-2-line",
      subItems: [
        {
          id: "calendar",
          label: "SCHEDULE",
          link: "/iframe?url=fitting#calendar",
          parentId: "fitting",
        },
        {
          id: "centers",
          label: "FITTING_CENTERS",
          link: "/iframe?url=fitting#centers",
          parentId: "fitting",
        },
        {
          id: "configs",
          label: "CONFIG",
          link: "/iframe?url=fitting#configs",
          parentId: "fitting",
        },
      ],
    },
    {
      id: "logistics",
      label: "LOGISTICS",
      costumIcon: "logisticsIcon",
      icon: "mdi mdi-truck-fast-outline",
      subItems: [
        {
          id: "over",
          label: "STOCK",
          link: "/iframe?url=logistics#over",
          parentId: "logistics",
        },
        {
          id: "arrivals",
          label: "ARRIVALS",
          link: "/iframe?url=logistics#arrivals",
          parentId: "logistics",
        },
        {
          id: "depart",
          label: "DEPARTURES",
          link: "/iframe?url=logistics#depart",
          parentId: "logistics",
        },
        {
          id: "history",
          label: "HISTORY",
          link: "/iframe?url=logistics#main",
          parentId: "logistics",
        },
        {
          id: "config",
          label: "CONFIG",
          parentId: "logistics",
          childItems: [
            {
              id: "config",
              label: "WAREHOUSES",
              link: "/iframe?url=logistics#config",
              parentId: "configuration",
            },
            {
              id: "shipping_rate",
              label: "SHIPPING_RATE",
              link: "/iframe?url=logistics/rates",
              parentId: "configuration",
            },
            {
              id: "carriers",
              label: "CARRIERS",
              link: "/iframe?url=logistics/carriers",
              parentId: "configuration",
            },
            {
              id: "Inventory",
              label: "INVENTORY",
              link: "/iframe?url=logistics/maintenance/inventory",
              parentId: "configuration",
            },
          ],
        },
      ],
    },
    {
      id: "client",
      label: "CLIENTS",
      icon: "ri-account-circle-line",
      link: "/iframe?url=clients",
    },
    {
      id: "reports",
      label: "REPORTS",
      icon: "ri-layout-grid-line",
      subItems: [
        {
          id: "sales",
          label: "SALES",
          link: "/iframe?url=reports#sales",
          parentId: "reports",
        },
        {
          id: "logistics",
          label: "LOGISTICS",
          link: "/iframe?url=reports#logistics",
          parentId: "reports",
        },
        {
          id: "marketing",
          label: "MARKETING",
          link: "/iframe?url=reports#marketing",
          parentId: "reports",
        },
        {
          id: "orders",
          label: "ORDERS",
          link: "/iframe?url=reports#orders",
          parentId: "reports",
        },
        {
          id: "employees",
          label: "EMPLOYEES",
          link: "/iframe?url=reports#employees",
          parentId: "reports",
        },
        {
          id: "functionalities",
          label: "FUNCTIONALITIES",
          link: "/iframe?url=reports#functionalities",
          parentId: "reports",
        },
        {
          id: "internalOperations",
          label: "INTERNAL_OPERATIONS",
          link: "/iframe?url=reports#internalOperations",
          parentId: "reports",
        },
      ],
    },
  ];

  useEffect(() => {
    handleMenuItems();
  }, [iscurrentState, iscurrentSubItemState, iscurrentChildItemState]);

  const handleMenuItems = () => {
    let tempArray = [];

    ApiResponse.map((api) => {
      // Initialize menuItem object with basic properties
      let menuItem = {
        id: api.id,
        costumIcon: api.costumIcon,
        isHeader: api.isHeader,
        label: api.label,
        icon: api.icon,
        link: api.link,
        // Define click event handler
        click: function (e) {
          setIscurrentState(iscurrentState === api.id ? "" : api.id);
          setIscurrentSubItemState("");
          updateIconSidebar(e);
        },
        stateVariables: iscurrentState === api.id ? true : false,
      };

      // Check if subItems exist and construct them if so
      if (api?.subItems && api?.subItems?.length > 0) {
        menuItem = {
          ...menuItem,
          subItems: api.subItems.map((subItem) => ({
            id: subItem.id,
            label: subItem.label,
            link: subItem.link,
            parentId: subItem.parentId,
            click: function (e) {
              setIscurrentSubItemState(
                iscurrentSubItemState === subItem.id ? "" : subItem.id
              );
              updateIconSidebar(e);
            },
            stateVariables: iscurrentSubItemState === subItem.id ? true : false,
            childItems: subItem.childItems
              ? subItem.childItems.map((childItem) => ({
                  id: childItem.id,
                  label: childItem.label,
                  link: childItem.link,
                  parentId: childItem.parentId,
                  click: function (e) {
                    setIscurrentChildItemState(childItem.id);
                    updateIconSidebar(e);
                  },
                }))
              : undefined,
          })),
        };
      }

      tempArray.push(menuItem);

      setItems({
        items: tempArray,
        currentState: iscurrentState,
        subItemCurrentState: iscurrentSubItemState,
        childItemCurrentState: iscurrentChildItemState,
      });
    });
  };

  return <React.Fragment>{items}</React.Fragment>;
};

// Export the Navdata component as default
export default Navdata;

import React, { useState, useCallback, useMemo, useEffect } from "react";
import Table from "../../../../Components/Common/Table";
import { useTranslation } from "react-i18next";
import { Spinner } from "reactstrap";

const TableChart = ({
  data: fetchedData,
  fetchRowDetails,
  loading,
  isLoadingDetail,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedRowData, setExpandedRowData] = useState({});
  const { t } = useTranslation();
  const [sortConfig, setSortConfig] = useState({
    field: null,
    direction: null,
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(fetchedData);
  }, [fetchedData]);

  const parseDateString = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const sortData = useCallback(
    (sortField) => {
      let direction = "asc";
      if (sortConfig.field === sortField) {
        if (sortConfig.field === sortField) {
          direction = sortConfig.direction === "asc" ? "desc" : "asc";
        }
      }
      setSortConfig({ field: sortField, direction });

      const sortedData = [...data].sort((a, b) => {
        if (sortField === "orderDate") {
          const dateA = parseDateString(a[sortField]);
          const dateB = parseDateString(b[sortField]);
          return direction === "asc" ? dateA - dateB : dateB - dateA;
        }
        if (sortField === "totalCost" || sortField === "totalSales") {
          const aValue = parseFloat(
            a[sortField].replace(/[^0-9,-]+/g, "").replace(",", ".")
          );
          const bValue = parseFloat(
            b[sortField].replace(/[^0-9,-]+/g, "").replace(",", ".")
          );
          return direction === "asc" ? aValue - bValue : bValue - aValue;
        }
        if (a[sortField] < b[sortField]) return direction === "asc" ? -1 : 1;
        if (a[sortField] > b[sortField]) return direction === "asc" ? 1 : -1;
        return 0;
      });

      setData(sortedData);
    },
    [data, sortConfig]
  );

  const tableColumns = {
    columns: [
      {
        field: "priority",
        header: t("PRIORITY"),
        body: (rowData) => <p className="text-left mb-0">{rowData.priority}</p>,
        sortable: true,
      },
      { field: "client", header: t("CLIENT") },
      { field: "orderDate", header: t("ORDER_DATE"), sortable: true },
      {
        field: "totalCost",
        header: t("TOTAL_ORDER_COST"),
        align: "right",
        sortable: true,
      },
      {
        field: "totalSales",
        header: t("TOTAL_SALES_ORDER"),
        align: "right",
        sortable: true,
      },
    ],
    size: "normal",
    scroll: { scrollable: false },
    editrow: { hasEdit: false },
  };

  const paginatedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;

    return data?.slice(firstPageIndex, lastPageIndex);
  }, [data, currentPage, rowsPerPage]);

  const expandedRowRender = (rowData) => {
    const detailColumns = {
      columns: [
        { field: "sku", header: t("SKU") },
        { field: "productName", header: t("PRODUCT_NAME") },
        { field: "color", header: t("COLOR"), align: "center" },
        { field: "size", header: t("SIZE"), align: "center" },
        { field: "totalQty", header: t("TOTAL_QTY"), align: "center" },
        { field: "cost", header: t("COST"), align: "right" },
        { field: "sales", header: t("SALES"), align: "right" },
      ],
      size: "normal",
      scroll: { scrollable: false },
      editrow: { hasEdit: false },
    };

    if (isLoadingDetail[rowData.key]) {
      return (
        <div className="detail-spinner">
          <Spinner className="m-auto">Loading...</Spinner>
        </div>
      );
    }

    const detailData = expandedRowData[rowData.key] || [];

    return (
      <Table
        tableInfo={detailData}
        tableColumns={detailColumns}
        loading={false}
        currentPage={1}
        rowsPerPage={detailData.length}
        footer={false}
      />
    );
  };

  const onRowExpand = useCallback(
    async (event) => {
      const { data: expandedRow } = event;
      setExpandedRows({ [expandedRow.key]: true });
      if (!expandedRowData[expandedRow.key]) {
        const data = await fetchRowDetails(expandedRow);
        setExpandedRowData((prev) => ({ ...prev, [expandedRow.key]: data }));
      }
    },
    [fetchRowDetails, expandedRowData]
  );

  const onRowCollapse = useCallback((event) => {
    const { data: collapsedRow } = event;
    setExpandedRows({ [collapsedRow.key]: false });
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  if (loading) {
    return (
      <Spinner className="m-auto mt-5 table-chart-spinner">Loading...</Spinner>
    );
  }

  if (data?.length === 0) {
    return <div className="m-auto mt-5 ">{t("SMART_ORDERS_NO_RESULT")}</div>;
  } else {
    return (
      <Table
        tableInfo={paginatedData}
        tableColumns={tableColumns}
        expandable
        expandedRowRender={expandedRowRender}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        expandedRows={expandedRows}
        loading={loading}
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleRowsPerPageChange}
        totalItems={fetchedData?.length}
        sortData={sortData}
        dataType="records"
      />
    );
  }
};

export default TableChart;

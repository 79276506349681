import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import "./_tabs.scss";

const UiTabs = ({ options, tab, setTab }) => {
  return (
    <Nav tabs className="nav-tabs tabs-nav-custom-tabs">
      {options.map((option, index) => (
        <NavItem className="tabs-nav-item" key={index}>
          <NavLink
            style={{ cursor: "pointer" }}
            className={tab === option.id ? "active" : ""}
            onClick={() => setTab(option.id)}
          >
            {option.title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default UiTabs;

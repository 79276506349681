import { useEffect, useState } from "react";
import {
  GetSKUConsumption,
  GetSKUConsumptionDetails,
} from "../../../infra/requests/ForecastRequests";
import TableChart from "./components/TableChart";
import SmartOrdersFilters from "./components/SmartOrdersFilters";
import CloudChart from "./components/CloudChart";
import BaseButton from "../../../Components/Common/Buttons/BaseButton";
import { Col, Row } from "reactstrap";

const CHARTVIEW = {
  cloudChart: 1,
  tableChart: 2,
};

const Index = () => {
  // Used for toggling between TableChart and CloudChart view
  const [currentChartView, setCurrentChartView] = useState(
    CHARTVIEW.tableChart
  );
  const [filters, setFilters] = useState({
    client: "",
    referenceOrSku: "",
    G1: true,
    G2: true,
    G3: true,
    G4: true,
    dates: null,
  });
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState({});

  useEffect(() => {
    setLoading(true);
    const fetchSKUConsumption = async () => {
      const { data } = await GetSKUConsumption({
        B2bclientId: filters.client || null,
        Barcode: filters.referenceOrSku || null,
        G1: filters.G1,
        G2: filters.G2,
        G3: filters.G3,
        G4: filters.G4,
        StartDate: filters.dates ? filters.dates[0].toISOString() : null,
        EndDate: filters.dates ? filters.dates[1].toISOString() : null,
      });
      let globalIndex = 0;
      const dataFiltered = data.companyProfiles.flatMap((company) =>
        company.b2bclients.flatMap((client) =>
          client.smartOrders.map((order) => ({
            key: globalIndex++,
            b2bclientId: client.b2bclientId,
            priority: company.companyProfile,
            client: client.b2bclientName,
            orderDate: new Date(order.orderDate).toLocaleDateString("en-GB"),
            date: order.orderDate,
            totalCost: new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR",
            }).format(order.orderTotalCostEUR),
            totalSales: new Intl.NumberFormat("pt-PT", {
              style: "currency",
              currency: "EUR",
            }).format(order.orderTotalSaleEUR),
          }))
        )
      );
      setTableData(dataFiltered);
      setLoading(false);
    };
    //TODO include filters in fetching data. Refetch when filters change

    fetchSKUConsumption();
  }, [filters]);

  const fetchRowDetails = async (record) => {
    setIsLoadingDetail((prev) => ({ ...prev, [record.key]: true }));
    const { b2bclientId, date } = record;
    try {
      const body = {
        B2bclientId: b2bclientId,
        OrderDate: date,
      };
      const { data } = await GetSKUConsumptionDetails(body);
      const dataFiltered = data.items.flat().map((item, key) => {
        const {
          sku,
          colorTranslation,
          productDescription,
          orderQty,
          lineCostValueEUR,
          lineBaseValueEUR,
          sizeNameEU,
        } = item;

        return {
          key,
          sku,
          productName: productDescription[1].name,
          color: colorTranslation[1].name,
          size: sizeNameEU,
          totalQty: orderQty,
          cost: new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          }).format(lineCostValueEUR),
          sales: new Intl.NumberFormat("pt-PT", {
            style: "currency",
            currency: "EUR",
          }).format(lineBaseValueEUR),
        };
      });
      setIsLoadingDetail((prev) => ({ ...prev, [record.key]: false }));
      return dataFiltered;
    } catch (error) {
      setIsLoadingDetail((prev) => ({ ...prev, [record.key]: false }));
      console.error("Error fetching row details:", error);
    }
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleRatingChange = (selectedRatings) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      rating: selectedRatings,
    }));
  };

  return (
    <div className="d-flex flex-column gap-2">
      <SmartOrdersFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onRatingChange={handleRatingChange}
      />
      <Row className="mt-3">
        <Col lg={12}>
          <div className="d-flex justify-content-center" style={{ gap: 20 }}>
            <BaseButton
              type={
                currentChartView === CHARTVIEW.tableChart
                  ? "normal"
                  : "secondary"
              }
              onClick={() => setCurrentChartView(CHARTVIEW.tableChart)}
              text={"Table Chart"}
            />
            <BaseButton
              type={
                currentChartView === CHARTVIEW.cloudChart
                  ? "normal"
                  : "secondary"
              }
              onClick={() => setCurrentChartView(CHARTVIEW.cloudChart)}
              text={"Cloud Chart"}
            />
          </div>
        </Col>
      </Row>

      {currentChartView === CHARTVIEW.tableChart && (
        <TableChart
          data={tableData}
          fetchRowDetails={fetchRowDetails}
          loading={loading}
          isLoadingDetail={isLoadingDetail}
        />
      )}
      {currentChartView === CHARTVIEW.cloudChart && (
        <CloudChart data={tableData} loading={loading} />
      )}
    </div>
  );
};

export default Index;

import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import Logo from "../../../assets/imagesNew/my-sky-pro-footer.png";

const Wizards = ({ wizards, setWizards, items, loading }) => {
  return (
    <div
      className="step-arrow-nav mb-4 bg-white"
      style={{
        borderRadius: 4,
      }}
    >
      {!loading ? (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <img
              className="mb-2"
              src={Logo}
              alt="logo"
              style={{ width: 140, height: 31 }}
            />
          </div>
          <Nav className="nav-justified">
            {items.map((wizard, index) => (
              <NavItem key={index} style={{ height: "35px !important" }}>
                <NavLink
                  style={{
                    cursor: "pointer",
                    // backgroundColor: wizards === index + 1 ? "red" : "blue",
                  }}
                  id={index}
                  className={classnames({
                    active: wizards === index + 1,
                    done: index < wizards,
                  })}
                  onClick={() => {
                    setWizards(wizard.id);
                  }}
                >
                  {wizard.title}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </>
      ) : (
        <div className="step-arrow-nav mb-4 w-100">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: 24,
            }}
          >
            <div
              className="bg-placeholder"
              style={{ width: 140, height: 31 }}
            />
          </div>
          <div className="nav-justified w-100">
            <div
              className="bg-placeholder"
              style={{
                height: "35px ",
                width: "100%",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Wizards;

import { useState } from "react";
import { Combobox, InputBase, useCombobox, Input } from "@mantine/core";
import { Label } from "reactstrap";
import { useTranslation } from "react-i18next";
import Icon from "../../icon";
import SimpleBar from "simplebar-react";

const SelectInputGroups = ({
  required,
  name,
  subtitle,
  label,
  options,
  placeholder,
  searchable,
  onChange,
  value,
  id,
  disabled,
  clearable,
}) => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(null);
  const [search, setSearch] = useState("");
  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch("");
    },
    onDropdownOpen: () => {
      searchable && combobox.focusSearchInput();
    },
  });

  const alloptions = options.reduce(
    (acc, group) => [...acc, ...group.options],
    []
  );

  const shouldFilterOptions = alloptions.every((item) => item !== search);

  const filteredGroups = options.map((group) => {
    const filteredOptions = shouldFilterOptions
      ? group.options.filter((item) =>
          item?.label?.toLowerCase().includes(search.toLowerCase().trim())
        )
      : group.options;

    return { ...group, options: filteredOptions };
  });

  const totalOptions = filteredGroups.reduce(
    (acc, group) => acc + group.options.length,
    0
  );

  const groups = filteredGroups.map((group, index) => {
    const options = group.options.map((item, index) => (
      <Combobox.Option
        value={item?.value}
        key={index}
        active={item?.value === currentValue}
      >
        {item?.label}
      </Combobox.Option>
    ));

    return (
      <Combobox.Group label={group.label.toUpperCase()} key={index}>
        <div
          style={{
            height: 1,
            borderBottom: "1px solid #DEDEDE",
          }}
        />
        {options}
      </Combobox.Group>
    );
  });

  const filteredGroupss = groups.filter((e) => e.props.children[1].length > 0);

  const handleDisplayValue = () => {
    const index = options.findIndex((item) =>
      item.options.find((option) => option.value === value)
    );
    if (index !== -1) {
      const option = options[index].options.find(
        (option) => option.value === value
      );
      return option.label;
    }
    return null;
  };

  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Combobox
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
          setCurrentValue(val);
          onChange(val);
          combobox.closeDropdown();
          combobox.updateSelectedOptionIndex("active");
        }}
        position="bottom"
        middlewares={{ flip: false, shift: false }}
      >
        <Combobox.Target>
          <InputBase
            component="button"
            type="button"
            name={name}
            id={id}
            disabled={disabled}
            onClick={() => combobox.toggleDropdown()}
            placeholder="Search value"
            rightSectionPointerEvents={value === null ? "none" : "all"}
            rightSection={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: 24,
                }}
              >
                {clearable && currentValue !== null && (
                  <div
                    onMouseDown={(event) => event.preventDefault()}
                    onClick={() => setCurrentValue(null)}
                    aria-label="Clear value"
                  >
                    <Icon
                      className="comboBox-close-icon"
                      style={{ marginRight: 10 }}
                      icon="closeIcon"
                      size={[18, 18]}
                    />
                  </div>
                )}
                <Icon icon="downArrowIcon" size={[11, 7]} />
              </div>
            }
          >
            {handleDisplayValue() || (
              <Input.Placeholder>{placeholder}</Input.Placeholder>
            )}
          </InputBase>
        </Combobox.Target>

        <Combobox.Dropdown>
          {searchable && (
            <Combobox.Search
              rightSectionPointerEvents={value === null ? "none" : "all"}
              value={search}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder={`${t("SEARCH")}...`}
              rightSection={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 24,
                  }}
                >
                  {search !== "" ? (
                    <div
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => setSearch("")}
                      aria-label="Clear value"
                    >
                      <Icon
                        className="comboBox-close-icon"
                        icon="closeIcon"
                        size={[18, 18]}
                      />
                    </div>
                  ) : (
                    <Icon icon="searchIcon" size={[15, 15]} />
                  )}
                </div>
              }
            />
          )}
          <SimpleBar style={{ maxHeight: "320px" }} autoHide={false}>
            <Combobox.Options>
              {totalOptions > 0 ? (
                filteredGroupss
              ) : (
                <Combobox.Empty>{t("NO_RESULTS_FOUND")}</Combobox.Empty>
              )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox.Dropdown>
      </Combobox>
      {subtitle && (
        <div
          style={{ marginTop: 5 }}
          className="bodyText_11 input_subtitle_text"
        >
          {subtitle}
        </div>
      )}
    </div>
  );
};

export default SelectInputGroups;

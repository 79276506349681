import Image1 from "../../../../../assets/imagesNew/battery/battery1.svg";
import Image2 from "../../../../../assets/imagesNew/battery/battery2.svg";
import Image3 from "../../../../../assets/imagesNew/battery/battery3.svg";
import Image4 from "../../../../../assets/imagesNew/battery/battery4.svg";

const Index = ({ data }) => {
  // Declare variables to hold image elements
  let imageElement = null;

  // Determine text color based on the content of data
switch (true) {
  case data === 0:
    imageElement = <img src={Image1} alt="CINZA" />;
    break;
  case data < 50 && data > 0:
    imageElement = <img src={Image4} alt="VERMELHO" />;
    break;
  case data >= 50 && data < 100:
    imageElement = <img src={Image3} alt="AMARELO" />;
    break;
  case data === 100:
    imageElement = <img src={Image2} alt="VERDE" />;
    break;
  default:
    break;
}

  return (
    <div className="text-center d-flex p-1 rounded border-primary">
      <span style={{ marginRight: "10px" }}>{imageElement}</span>
      {data}%
    </div>
  );
};

export default Index;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Breadcrumb from "../../Components/Common/Breadcrumb";
import { useParams } from "react-router-dom";
import {
  EditProductProperties,
  GetProduct,
} from "../../infra/requests/ProductsRequests";
import ProductCard from "./ProductCard";
import "./styles.scss"; // Import the styles.scss file
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import ProductDetailTabs from "./ProductDetail/components/ProductDetailTabs";

const Index = () => {
  const [product, setProduct] = useState({});
  const [margin, setMargin] = useState();
  const { productId } = useParams();
  const currencies = useSelector((state) => state.Info.data.currencies);
  const [lastAcquisition, setLastAcquisition] = useState();

  const breadcrumbData = {
    pageTitle: "Articles Details",
    items: [{ title: "Article", url: "/articles" }],
  };

  const {
    isB2c,
    productTypeId,
    mainCategoryId,
    // subCategory,
    subCategoryId,
    packageDepth,
    packageHeight,
    packageWeight,
    packageWidth,
    productTranslation,
    productCharacteristicValue,
    productTechnology,
    categoryId,
  } = product?.productPropertiesSection || {};

  const formatedCharacteristics = productCharacteristicValue?.map(
    (characteristic) => {
      const { characteristicId, characteristic: characteristicObject } =
        characteristic.characteristicValue;

      return {
        characteristicId: characteristicId,
        characteristicTranslation:
          characteristicObject.characteristicTranslation,
        characteristicValueId: characteristic.characteristicValueId,
      };
    }
  );

  const formatedTechnologies = productTechnology?.map(
    (technology) => technology.technology
  );

  const nameAndDescriptionValues =
    productTranslation?.reduce((acc, item) => {
      console.log({ item });
      if (item.name || item.description) {
        acc.push({
          languageId: item.languageId,
          name: item.name || "",
          description: item.description || "",
        });
      }
      return acc;
    }, []) || [];

  const SEOValues =
    productTranslation?.reduce((acc, item) => {
      if (item.metaTitle || item.metaDescription) {
        acc.push({
          languageId: item.languageId,
          metaTitle: item.metaTitle || "",
          metaDescription: item.metaDescription || "",
        });
      }
      return acc;
    }, []) || [];

  //Initial Values only defined for Product Detail so far
  const initialValues = {
    business: isB2c,
    type: productTypeId,
    gender: mainCategoryId,
    category: categoryId,
    subCategory: subCategoryId,
    characteristics: formatedCharacteristics,
    technologies: formatedTechnologies,
    packageDepth: packageDepth,
    packageHeight: packageHeight,
    packageWeight: packageWeight,
    packageWidth: packageWidth,
    nameAndDescription: nameAndDescriptionValues,
    seo: SEOValues,
  };

  useEffect(() => {
    const fetchProduct = async () => {
      const { data } = await GetProduct(productId);
      setProduct(data);

      //check if there is margin from the api , otherwise calculate on frontend
      const marginPercentage = data.productPriceSection?.marginPercentage;

      //Futura verificação para o campo lastAcquisition
      calculateAcquisition(data);

      if (marginPercentage) {
        const calculatedMargin = calculateGrossMargin(data);
        setMargin(calculatedMargin);
      } else {
        setMargin(marginPercentage);
      }
    };
    fetchProduct();
  }, [productId]);

  const calculateAcquisition = (product) => {
    const productSuppliers =
      product.productCompositionSection?.productCostFiles[0]?.productSupplier ||
      [];

    const rawMaterialList =
      product.productCompositionSection?.productCostFiles[0]?.rawMaterialList ||
      [];

    let totalSupplierCost = 0;

    // Calculate total from productSupplier
    for (let index = 0; index < productSuppliers.length; index++) {
      const supplierCost = productSuppliers[index].cost;
      const supplierUnits = productSuppliers[index].units || 0;
      totalSupplierCost += supplierCost * supplierUnits;
    }

    let totalRawMaterialCost = 0;

    // Calculate total from rawMaterialList
    for (let index = 0; index < rawMaterialList.length; index++) {
      const rawMaterialCost = rawMaterialList[index].baseValue;
      const rawMaterialQuantity = rawMaterialList[index].quantity || 0;
      totalRawMaterialCost += rawMaterialCost * rawMaterialQuantity;
    }

    const totalCost = totalSupplierCost + totalRawMaterialCost;

    setLastAcquisition(totalCost);
  };

  const calculateGrossMargin = (product) => {
    const baseValue = product.productPriceSection?.baseValue;
    if (!baseValue) {
      return 0;
    }

    const productSuppliers =
      product.productCompositionSection?.productCostFiles[0]?.productSupplier ||
      [];

    const rawMaterialList =
      product.productCompositionSection?.productCostFiles[0]?.rawMaterialList ||
      [];

    let totalSupplierCost = 0;

    // Calculate total from productSupplier
    for (let index = 0; index < productSuppliers.length; index++) {
      const supplierCost = productSuppliers[index].cost;
      const supplierUnits = productSuppliers[index].units || 0;
      totalSupplierCost += supplierCost * supplierUnits;
    }

    let totalRawMaterialCost = 0;

    // Calculate total from rawMaterialList
    for (let index = 0; index < rawMaterialList.length; index++) {
      const rawMaterialCost = rawMaterialList[index].baseValue;
      const rawMaterialQuantity = rawMaterialList[index].quantity || 0;
      totalRawMaterialCost += rawMaterialCost * rawMaterialQuantity;
    }

    const totalCost = totalSupplierCost + totalRawMaterialCost;

    if (totalCost === 0) {
      return 0;
    }

    const grossMargin = 1 - totalCost / baseValue;
    return grossMargin * 100;
  };

  const baseValue = product.productPriceSection?.baseValue;
  const otherPrices = product.productPriceSection?.productPrice || [];
  const additionalPrice = otherPrices.find((price) => price.currencyId !== 1);

  const getCurrencySymbol = (currencyId) => {
    const currency = currencies.find((curr) => curr.currencyId === currencyId);
    return currency ? currency.symbol : "";
  };

  const ConstructProperties = (values) => {
    const {
      gender,
      business,
      category,
      // subCategory,
      nameAndDescription,
      seo,
      type,
      ...rest
    } = values;

    // used to combine SEO and NameAndDescription
    const combinedTranslations = () => {
      let result = [];

      if (nameAndDescription && nameAndDescription.length > 0) {
        nameAndDescription.forEach((translation) => {
          const matchingSEO = seo?.find(
            (seoItem) => seoItem.languageId === translation.languageId
          );
          result.push({
            languageId: translation.languageId,
            name: translation.name,
            description: translation.description,
            metaTitle: matchingSEO?.metaTitle || null,
            metaDescription: matchingSEO?.metaDescription || null,
          });
        });
      }

      if (seo && seo.length > 0) {
        seo.forEach((seoItem) => {
          const existingEntry = result.find(
            (item) => item.languageId === seoItem.languageId
          );
          if (!existingEntry) {
            result.push({
              languageId: seoItem.languageId,
              metaTitle: seoItem.metaTitle,
              metaDescription: seoItem.metaDescription,
            });
          }
        });
      }
      return result;
    };

    return {
      ...rest,
      ProductTypeId: type,
      IsB2c: business,
      MainCategoryId: gender?.toString(),
      CategoryId: category?.toString(),
      SubCategory: "", //subCategory?.toString(), //FIXME when subcategory is fixed
      ReferenceCode: product?.referenceCode,
      ProductTranslation: combinedTranslations(),
      ProductImage: product?.productPropertiesSection?.productImage, // FIXME fix when image tab is developed
      ProductTechnology: values.technologies.map((elem) => ({
        technologyId: elem.technologyId.toString(),
      })),
      ProductCharacteristicValue: values.characteristics.map((elem) => ({
        CharacteristicId: elem.characteristicId,
        characteristicValueId:
          elem.characteristicValueId || elem.values[0].characteristicValueId, // Temp fix while Characteristic Value is not chosen during characteristic select
      })),
    };
  };

  const handleFormSubmit = async (values) => {
    try {
      await EditProductProperties(productId, ConstructProperties(values));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-content">
      <div className="breadcrumb-bar">
        <Breadcrumb breadcrumbData={breadcrumbData} />
      </div>
      <Form
        onSubmit={(values, form) => handleFormSubmit(values, form)}
        mutators={{ ...arrayMutators }}
        keepDirtyOnReinitialize
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => (
          // form, pristine, values can be added to props
          <form onSubmit={handleSubmit}>
            <ProductCard
              product={product}
              margin={margin}
              lastAcquisition={lastAcquisition}
              baseValue={baseValue}
              additionalPrice={additionalPrice}
              getCurrencySymbol={getCurrencySymbol}
              isSaving={submitting}
              animate={false}
            />
            <ProductDetailTabs />
          </form>
        )}
      />
    </div>
  );
};

export default Index;

import React from "react";
import Flatpickr from "react-flatpickr";
import { Label } from "reactstrap";

const TimeInput = ({
  label,
  required,
  enableTime,
  value,
  onChange,
  mode,
  name,
  id,
  noCalendar,
}) => {
  return (
    <div className="mt-2">
      {label && (
        <Label className="heading_4" htmlFor={name}>
          {label}
          {required && <span className="text-danger"> *</span>}
        </Label>
      )}
      <Flatpickr
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        className="form-control"
        placeholder={noCalendar ? "HH-mm" : "dd-mm-yyyy"}
        options={{
          enableTime: enableTime,
          noCalendar: noCalendar,
          dateFormat: noCalendar ? "H:i" : "d-m-Y",
          mode: mode ? mode : "single",
        }}
      />
    </div>
  );
};

export default TimeInput;

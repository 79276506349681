import moment from "moment";

export const ConstructQuery = (filters) => {
  let result = "";
  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== "") {
        if (
          typeof filters[key] === "string" ||
          typeof filters[key] === "number"
        ) {
          result += `&${key}=${filters[key]}`;
        } else if (moment.isMoment(filters[key])) {
          result += `&${key}=${moment(filters[key]).toISOString()}`;
        }
      }
    });
  }
  return result;
};

export const ConstructSort = (sort) => {
  let result = "";
  if (sort && sort.order && sort.field) {
    if (sort.order === "ascend") {
      result = `&sortBy=${sort.field}&sortOrder=asc`;
    }
    if (sort.order === "descend") {
      result = `&sortBy=${sort.field}&sortOrder=desc`;
    }
  }
  return result;
};

export default ConstructQuery;

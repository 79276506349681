import { Field } from "react-final-form";
import ButtonIcon from "../../../../../../Components/Common/Buttons/ButtonIcon";
import TextInputAdaptor from "../../../../../../Components/Common/FormComponentAdaptors/TextInputAdaptor";
import { ProductTranslationLanguages } from "../../../../../../infra/constants/ProductTranslationLanguages";

const NameAndDescriptionRow = ({ name, index, onRemove }) => {
  return (
    <tr>
      <td style={{ paddingRight: "50px" }}>
        <Field
          name={`${name}.languageId`}
          render={({ input }) => {
            const language = ProductTranslationLanguages.find(
              (lang) => lang.languageId === input.value
            );
            return <div className="language bodyText_9">{language?.name}</div>;
          }}
        />
      </td>
      <td style={{ paddingRight: "20px", width: 400 }}>
        <Field
          name={`nameAndDescription[${index}].name`}
          component={TextInputAdaptor}
        />
      </td>
      <td style={{ paddingRight: 10, paddingBottom: 5, width: 600 }}>
        <Field
          name={`nameAndDescription[${index}].description`}
          render={(props) => (
            <TextInputAdaptor
              {...props}
              type="textarea"
              style={{ height: "1rem" }}
            />
          )}
        />
      </td>
      <td>
        <div className="siu">
          <ButtonIcon
            htmlType="button"
            icon="trash"
            color="red"
            onClick={onRemove}
          />
        </div>
      </td>
    </tr>
  );
};

export default NameAndDescriptionRow;

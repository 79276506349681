import React from "react";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import { useDispatch } from "react-redux";
import { getProfile } from "./slices/auth/profile/thunk";
import { getInfo } from "./slices/info/thunk";

function App() {
  const dispatch = useDispatch();
  const authToken = localStorage.getItem("authToken");

  if (authToken) {
    dispatch(getProfile());
    dispatch(getInfo());
  }

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;

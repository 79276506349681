import { useState, useEffect } from "react";
import { Combobox, useCombobox, Group, Checkbox } from "@mantine/core";
import { useTranslation } from "react-i18next";
import Icon from "../../icon";
import SimpleBar from "simplebar-react";

const MultipleDropDown = ({
  children,
  onChange,
  options,
  searchable,
  value,
  top,
  left,
  all,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [currentValue, setCurrentValue] = useState(value);
  const [selectAll, setSelectAll] = useState(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),

    onDropdownOpen: () => {
      combobox.updateSelectedOptionIndex("active");
      searchable && combobox.focusSearchInput();
    },
  });

  useEffect(() => {
    if (currentValue?.length > 0) {
      onChange(currentValue);
    } else {
      onChange([]);
    }
  }, [currentValue]);

  const handleKeyDown = (event) => {
    if (currentOptions?.length === 1 && event?.key === "Enter") {
      setCurrentValue((current) =>
        current?.includes(currentOptions[0]?.value)
          ? current?.filter((v) => v !== currentOptions[0]?.value)
          : [...current, currentOptions[0].value]
      );

      setSearch("");
    }
  };

  const handleValueSelect = (val) =>
    setCurrentValue((current) =>
      current?.includes(val)
        ? current?.filter((v) => v !== val)
        : [...current, val]
    );

  const currentOptions = options?.filter((item) =>
    item?.label?.toLowerCase()?.includes(search?.toLowerCase()?.trim())
  );

  useEffect(() => {
    handleSelectAll();
  }, [selectAll]);

  const handleSelectAll = () => {
    if (selectAll) {
      setCurrentValue(options.map((item) => item.value));
    } else {
      setCurrentValue([]);
      onChange([]);
    }
  };

  return (
    // <div className="mt-2">
    <div style={{ zIndex: 99999 }}>
      <Combobox
        store={combobox}
        onOptionSubmit={handleValueSelect}
        withinPortal={false}
        position="bottom"
        middlewares={{ flip: false, shift: false }}
      >
        <Combobox.Target onClick={() => combobox.toggleDropdown()}>
          {children}
        </Combobox.Target>

        {/* <Combobox.Dropdown style={{ top: top || 0, width: "max-content" }}> */}
        <Combobox.Dropdown
          value={left ? left : "none"}
          style={{
            width: "max-content",
            marginTop: top || 0,
          }}
        >
          {searchable && (
            <Combobox.Search
              value={search}
              onKeyDown={(e) => handleKeyDown(e)}
              onChange={(event) => setSearch(event.currentTarget.value)}
              placeholder={`${t("SEARCH")}...`}
              rightSectionPointerEvents={search === "" ? "none" : "all"}
              rightSection={
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginRight: 24,
                  }}
                >
                  {search !== "" ? (
                    <div
                      onMouseDown={(event) => event.preventDefault()}
                      onClick={() => {
                        setSearch("");
                        onChange([]);
                      }}
                      aria-label="Clear value"
                    >
                      <Icon
                        className="comboBox-close-icon"
                        icon="closeIcon"
                        size={[18, 18]}
                      />
                    </div>
                  ) : (
                    <Icon icon="searchIcon" size={[15, 15]} />
                  )}
                </div>
              }
            />
          )}
          {all && (
            <Combobox.Options>
              {currentOptions?.length > 1 && (
                <div className="check-all-wrapper">
                  <Checkbox
                    className="check-all-option"
                    checked={selectAll}
                    onChange={() => setSelectAll(event.target.checked)}
                  />
                </div>
              )}
            </Combobox.Options>
          )}
          <SimpleBar
            style={{
              maxHeight: "320px",
              maxWidth: "100%",
              overflowX: "hidden",
            }}
            autoHide={false}
          >
            <Combobox.Options>
              {currentOptions?.length > 0 ? (
                currentOptions.map((item, index) => (
                  <Combobox.Option
                    value={item?.value}
                    key={index}
                    active={
                      currentValue?.includes(item?.value) ||
                      (currentOptions?.length === 1 && index === 0)
                        ? true
                        : false
                    }
                    selected={
                      currentValue?.includes(item?.value) ||
                      (currentOptions?.length === 1 && index === 0)
                        ? true
                        : false
                    }
                  >
                    <div>
                      <Group gap="sm" align="flex-start">
                        <Checkbox
                          checked={currentValue?.includes(item?.value)}
                          onChange={() => {}}
                          aria-hidden
                          tabIndex={-1}
                          style={{ pointerEvents: "none", paddingTop: 3 }}
                        />
                        <span>{item?.label}</span>
                      </Group>
                    </div>
                  </Combobox.Option>
                ))
              ) : (
                <Combobox.Empty>{t("NO_RESULTS_FOUND")}</Combobox.Empty>
              )}
            </Combobox.Options>
          </SimpleBar>
        </Combobox.Dropdown>
      </Combobox>
    </div>
  );
};

export default MultipleDropDown;

import SelectInput from "../Inputs/SelectInput/SelectInput";

const SelectInputAdaptor = ({ input, ...remainingProps }) => {
  return (
    <SelectInput
      {...input}
      {...remainingProps}
      value={input.value}
      onChange={(value) => {
        input.onChange(value);
      }}
    />
  );
};

export default SelectInputAdaptor;

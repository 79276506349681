import { setSize } from "../../../helpers/setSize";
import styled, { css } from "styled-components";

export const transitionsDelay = "0.3s";

export const SvgWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ color }) =>
    !!color &&
    css`
      & svg,
      & path {
        ${({ type }) => type === "fill" && `fill: ${color}`};
        ${({ type }) => type === "stroke" && `stroke: ${color}`};
      }
    `};

  ${({ hover }) =>
    !!hover &&
    css`
      cursor: pointer;

      & svg,
      & path {
        transition: fill ${transitionsDelay} linear,
          stroke ${transitionsDelay} linear;
      }

      &:hover {
        & svg,
        & path {
          ${({ type }) => type === "fill" && `fill: ${hover}`};
          ${({ type }) => type === "stroke" && `stroke: ${hover}`};
        }
      }
    `};

  & svg {
    ${({ size }) => setSize(size)};
  }
`;

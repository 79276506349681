import React, { useState } from "react";
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from "reactstrap";

//import images
import Outlook from "../../assets/imagesNew/header/outlook.png";
import Sharepoint from "../../assets/imagesNew/header/sharepoint.png";
import Confluence from "../../assets/imagesNew/header/confluence.png";
import Trello from "../../assets/imagesNew/header/trello.png";
import Jira from "../../assets/imagesNew/header/jira.png";
import Teams from "../../assets/imagesNew/header/teams.png";
import { Link } from "react-router-dom";

const WebAppsDropdown = () => {
  const [isWebAppDropdown, setIsWebAppDropdown] = useState(false);
  const toggleWebAppDropdown = () => {
    setIsWebAppDropdown(!isWebAppDropdown);
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={isWebAppDropdown}
        toggle={toggleWebAppDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          tag="button"
          type="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-category-alt fs-22"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 fw-semibold fs-15"> Web Apps </h6>
              </Col>
            </Row>
          </div>

          <div className="p-2">
            <div className="row g-0">
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://outlook.office.com/mail/"
                >
                  <img src={Outlook} alt="outlook" />
                  <span>Outlook</span>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://myskypro.sharepoint.com/sites/SkyProS.A/SitePages/Home.aspx"
                >
                  <img src={Sharepoint} alt="sharepoint" />
                  <span>Sharepoint</span>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://wearskypro.atlassian.net/wiki/home"
                >
                  <img src={Confluence} alt="confluence" />
                  <span>Confluence</span>
                </Link>
              </Col>
            </div>

            <div className="row g-0">
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://trello.com/"
                >
                  <img src={Trello} alt="trello" />
                  <span>Trello</span>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://wearskypro.atlassian.net/"
                >
                  <img src={Jira} alt="jira" />
                  <span>Jira</span>
                </Link>
              </Col>
              <Col>
                <Link
                  target="_blank"
                  className="dropdown-icon-item"
                  to="https://teams.microsoft.com/v2/"
                >
                  <img src={Teams} alt="teams" />
                  <span>Teams</span>
                </Link>
              </Col>
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default WebAppsDropdown;

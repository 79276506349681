import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ProfileReducer from "./auth/profile/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

//Info
import InfoReducer from "./info/reducer";

const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  Profile: ProfileReducer,
  APIKey: APIKeyReducer,
  Info: InfoReducer,
});

export default rootReducer;

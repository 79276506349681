import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//import images
import avatar1 from "../../assets/imagesNew/profile-avatar.jpg";
import withRouter from "./withRouter";

const ProfileDropdown = ({ router }) => {
  const { t } = useTranslation();
  const profiledropdownData = createSelector(
    (state) => state.Profile,
    (state) => ({
      user: state.user,
    })
  );
  const { user } = useSelector(profiledropdownData);

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={avatar1}
              alt="Header Avatar"
            />
            <span className="text-end ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {user.fullName}
              </span>
              <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                {user.profileName}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">{t("WELCOME")}</h6>
          <DropdownItem className="p-0">
            <a
              href="/iframe?url=profile"
              onClick={() => router.navigate("/iframe?url=profile")}
              className="dropdown-item"
            >
              <i className="mdi mdi-account-circle-outline text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{t("MY_PROFILE")}</span>
            </a>
          </DropdownItem>
          <DropdownItem className="p-0">
            <a
              href="/iframe?url=skypro"
              onClick={() => router.navigate("/iframe?url=skypro")}
              className="dropdown-item"
            >
              <i className="ri-settings-3-line text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{t("SETTINGS")}</span>
            </a>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <h6 className="dropdown-header">{t("SITE")}</h6>
          <DropdownItem className="p-0">
            <a
              href="/iframe?url=mailConfiguration"
              onClick={() => router.navigate("/iframe?url=mailConfiguration")}
              to="#"
              className="dropdown-item"
            >
              <i className="ri-settings-3-line text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{t("SETTINGS")}</span>
            </a>
          </DropdownItem>
          <DropdownItem className="p-0">
            <a
              href="/iframe?url=marketing"
              onClick={() => router.navigate("/iframe?url=marketing")}
              to="/pages-faqs"
              className="dropdown-item"
            >
              <i className="las la-bullhorn text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle">{t("MARKETING")}</span>
            </a>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem className="p-0">
            <Link to="/logout" className="dropdown-item">
              <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
              <span className="align-middle" data-key="t-logout">
                {t("LOGOUT")}
              </span>
            </Link>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withRouter(ProfileDropdown);

import React from "react";

const index = ({ data, date }) => {
  return (
    <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
      <span className="text-center p-1 rounded border-primary">
        {data}
        <span style={{ color: "#F7B84B" }}> ({date})</span>
      </span>
    </div>
  );
};

export default index;

import flagus from "../assets/images/flags/us.svg";
import flagspain from "../assets/images/flags/pt.svg";

const languages = {
  pt: {
    label: "Português",
    flag: flagspain,
  },
  en: {
    label: "Inglês",
    flag: flagus,
  },
};

export default languages;

import React, { useEffect, useRef, useState } from "react";
import withRouter from "../../Components/Common/withRouter";

const IFrame = ({ router }) => {
  const iframeRef = useRef(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const authToken = localStorage.getItem("authToken");
  const queryParams = new URLSearchParams(router.location.search);
  const urlParam = queryParams.get("url");
  const urlHash = queryParams.get(
    router.location.hash === "" ? "" : router.location.hash
  );

  useEffect(() => {
    // This message is only sent on this pages first call when iframe has loaded
    const sendMessageToIframe = () => {
      const message = {
        type: "authentication",
        token: authToken,
        isHeaderVisible: "false",
      };
      if (iframeRef.current && iframeRef.current.contentWindow) {
        iframeRef.current.contentWindow.postMessage(
          message,
          window.env.REACT_APP_IFRAME_PARENT_URL
        );
      }
    };
    sendMessageToIframe();
  }, [iframeLoaded]);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
    const frame = document.getElementById("iframe");
    frame.style.height = "calc(100vh - 77px)";
    frame.style.width = "100%";
  };

  const handleIFrameLink = () => {
    if (urlParam) {
      return `${window.env.REACT_APP_IFRAME_PARENT_URL}/${urlParam}${
        urlHash !== "" && router.location.hash
      }`;
    } else {
      return window.env.REACT_APP_IFRAME_PARENT_URL;
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const handleMessage = (event) => {
    if (event?.data?.message === "logout") {
      return router.navigate("/logout");
    }
  };

  return (
    <div
      style={{
        height: "calc(100vh - 77px)",
        width: "100%",
        marginTop: 77,
        overflow: "hidden",
      }}
    >
      <iframe
        id="iframe"
        style={{
          margin: 0,
          padding: 0,
        }}
        ref={iframeRef}
        onLoad={handleIframeLoad}
        src={handleIFrameLink()}
        // Enable if any restrictions are needed
        // sandbox="allow-same-origin allow-scripts allow-forms"
      />
    </div>
  );
};

export default withRouter(IFrame);

import React from "react";

const Index = ({ data }) => {
  const handleStatus = () => {
    if (data?.active === "unknown") {
      return "bg-status-closed";
    } else if (data?.active === "active") {
      return "bg-status-shipped";
    } else if (data?.active === "canceled") {
      return "bg-status-canceled";
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      <span
        className={`${handleStatus()} text-center p-1 heading_8 status-table-cell rounded border-primary text-white`}
      >
        {data?.active?.toLowerCase()}
      </span>
    </div>
  );
};

export default Index;

import client from "../config/AxiosConfig";

export const GetInformation = async (filters) =>
  client.get(`/Logistics/GetStatistics?${filters}`);

export const GetOverview = async (page, pageSize, filters) =>
  client.get(
    `/Logistics/GetOverview?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  );

export const GetWarehousesList = async (page, pageSize, filters) =>
  client.get(
    `/Logistics/ListWarehouses?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  );

export const GetShippingRates = async () =>
  client.get("/Logistics/ListShippingRates");

export const SaveShippingRates = async (data) =>
  client.put("/Logistics/SaveShippingRates", data);

export const GetCarriers = async (page, pageSize) =>
  client.get(`/Logistics/ListCarriers?ItemsPerPage=${pageSize}&Page=${page}`);

export const GetCarrierDetail = async (id) =>
  client.get(`/Logistics/GetCarrierDetail/${id}`);

export const CreateNewCarrier = async (data) =>
  client.post("/Logistics/CreateNewCarrier", data);

export const SaveCarrier = async (id, data) =>
  client.put(`/Logistics/PutCarrierDetail/${id}`, data);

export const GetWarehouseDetail = async (id) =>
  client.get(`/Logistics/GetWarehouseDetail/${id}`);

export const CreateNewWarehouse = async (data) =>
  client.post("/Logistics/CreateNewWarehouse", data);

export const SaveWarehouse = async (id, data) =>
  client.put(`/Logistics/PutWarehouseDetail/${id}`, data);

export const GetWarehousesByOwner = async (id = 0) =>
  client.get(`Warehouses/GetWarehouseLocationsByOwner/${id}`);

export const GetConfectionWarehouses = async () =>
  client.get("Warehouses/GetConfectionWarehouses");

export const GetProductionWarehouses = async () =>
  client.get("Warehouses/GetProductiveWarehouses");

export const GetMaintenanceList = async (page, pageSize, filters) =>
  client.get(
    `/Logistics/ListMaintenance?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  );

export const GetInventoryList = async (page, pageSize, filters) =>
  client.get(
    `/Logistics/ListInventoryCount?ItemsPerPage=${pageSize}&Page=${page}${filters}`
  );

export const PutInventoryCount = async (data) =>
  client.put("/Logistics/PutInventoryCount", data);

export const GetAllWarehouses = async () =>
  client.get("Warehouses/GetWarehousesDrop");

export const GetAllWarehouseTypes = async () =>
  client.get("/Warehouses/GetWarehouseTypesDrop");

export const GetAllReturnReasons = async () =>
  client.get("/Logistics/GetReturnReasonsDrop");

export const GetWarehousesForStockMovements = async (id) =>
  client.get(`/Warehouses/GetWarehousesForStockMovements/${id}`);

export const PostStockTransfer = async (data) =>
  client.post("/Logistics/PostStockTransfer", data);

export const GetMovementTypeList = async () =>
  client.get("/Logistics/GetMovementTypesDrop");

export const GetMovementStatusList = async () =>
  client.get("/Movements/GetMovementStatusTranslationDDL");

export const GetFakeProducts = async () =>
  client.get("/Logistics/GetFAKEProductsDrop");

export const GetFakeProductCombination = async (id) =>
  client.get(`/Logistics/GetFAKEProductCombinationsDrop/${id}`);

export const GetForecastChart = async ({
  referenceCode,
  referenceSKU,
  startDate,
  endDate,
  timespan,
}) =>
  client.get(
    `/Logistics/GetForecastChart/${referenceCode}/${referenceSKU}/${startDate}/${endDate}/${timespan}`
  );

export const CreatePickingList = async (data) =>
  client.post("/Logistics/CreateSimplePickingList", data);

// export const GetStockHistory = async (page, pageSize, filters) =>
//   client.post(
//     `/Logistics/GetStockHistory?ItemsPerPage=${pageSize}&Page=${page}${filters}`
//   );

export const GetStockHistory = async (data) =>
  client.post("/Logistics/GetStockHistory", data);

import { Combobox, useCombobox } from "@mantine/core";
import { useState, useRef } from "react";
import DatePickerComponent from "../DateInput/DatePickerComponent";

const DropDownDate = ({ children, onChange, value }) => {
  const combobox = useCombobox();

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  return (
    <Combobox
      withinPortal={false}
      store={combobox}
      position="bottom"
      middlewares={{ flip: false, shift: false }}
      // opened={isOpen}
    >
      <Combobox.Target
        onClick={() => {
          setIsOpen(!isOpen);
          combobox.toggleDropdown();
        }}
      >
        {children}
      </Combobox.Target>

      <Combobox.Dropdown
        ref={dropdownRef}
        style={{
          zIndex: 99999,
        }}
      >
        <div style={{ padding: 5, minWidth: 200 }}>
          <DatePickerComponent
            type="range"
            value={value}
            onChange={(date_range) => {
              onChange(date_range);
            }}
          />
        </div>
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default DropDownDate;

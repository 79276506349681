import { Form } from "reactstrap";
import { useFormik } from "formik";
import { Button } from "@mantine/core";
import React, { useState } from "react";
import * as Yup from "yup";
import TextInput from "../../Components/Common/Inputs/TextInput/TextInput";
import DateInput from "../../Components/Common/Inputs/DateInput/DateInput";
import TimeInput from "../../Components/Common/Inputs/TimeInput/TimeInput";
import SelectInput from "../../Components/Common/Inputs/SelectInput/SelectInput";
import SelectInputGroups from "../../Components/Common/Inputs/SelectInputGroups/SelectInputGroups";
import MultipleSelectInput from "../../Components/Common/Inputs/MultipleSelectInput/MultipleSelectInput";
import MultipleSelectCreatableInput from "../../Components/Common/Inputs/MultipleSelectCreatableInput/MultipleSelectCreatableInput";
import TextPillsInput from "../../Components/Common/Inputs/TextPillsInput/TextPillsInput";
import DropDown from "../../Components/Common/Inputs/DropDown/DropDown";
import MultipleDropDown from "../../Components/Common/Inputs/MultipleDropDown/MultipleDropDown";
import DropDownGroups from "../../Components/Common/Inputs/DropDownGroups/DropDownGroups";
import Wizards from "../../Components/Common/Wizards/Wizards";
import FilesInput from "../../Components/Common/Inputs/FilesInput/FilesInput";

const BasicElements = () => {
  const [wizards, setWizards] = useState(1);
  const wizardItems = [
    { id: 1, title: "General" },
    { id: 2, title: "Description" },
    { id: 3, title: "Finish" },
  ];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {},
    validationSchema: Yup.object({
      basic_input: Yup.string(),
      input_with_placeholder: Yup.string(),
      input_with_value: Yup.string(),
      read_only_input_plain_text: Yup.string(),
      read_only_input: Yup.string(),
      disabled_input: Yup.string(),
      input_with_icon: Yup.string(),
      input_date: Yup.date(),
      input_password: Yup.string(),
      input_form_text: Yup.string().min(
        8,
        "Input must be at least 3 characters long"
      ),
      input_with_autocomplete: Yup.string(),
      input_without_autocomplete: Yup.string(),
      input_text_area: Yup.string(),
      input_search: Yup.string(),
      file: Yup.mixed(),
      file_multiple: Yup.mixed(),
      file_disabled: Yup.mixed(),

      name: Yup.string(),

      date: Yup.date(),
      date_multiple: Yup.array().of(Yup.date()),
      date_range: Yup.array().of(Yup.date()),
      time: Yup.date(),
      select_with_search: Yup.string(),
      select: Yup.string(),
      multiple_select: Yup.array().of(Yup.string()),
      dropdown: Yup.string(),
      multiple_dropdown: Yup.array().of(Yup.string()),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  return (
    <div className="page-content bg-white ">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
        className="container"
      >
        <TextInput
          label="Basic Input"
          name="basic_input"
          id="basic_input"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.basic_input || ""}
          invalid={
            validation.touched.basic_input && validation.errors.basic_input
              ? true
              : false
          }
        />
        <TextInput
          label="Input with placeholder"
          name="input_with_placeholder"
          id="input_with_placeholder"
          placeholder="Mark"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_with_placeholder || ""}
          invalid={
            validation.touched.input_with_placeholder &&
            validation.errors.input_with_placeholder
              ? true
              : false
          }
        />
        <TextInput
          label="Input with value"
          name="input_with_value"
          id="input_with_value"
          placeholder="Mark"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_with_value || "Mark"}
          invalid={
            validation.touched.input_with_value &&
            validation.errors.input_with_value
              ? true
              : false
          }
        />
        <TextInput
          label="Readonly Plain Text Input"
          name="read_only_input_plain_text"
          id="read_only_input_plain_text"
          placeholder="Mark"
          type="text"
          // onChange={validation.handleChange}
          //  onBlur={validation.handleBlur}
          value={
            validation.values.read_only_input_plain_text || "Readonly input"
          }
          invalid={
            validation.touched.read_only_input_plain_text &&
            validation.errors.read_only_input_plain_text
              ? true
              : false
          }
          plainText={true}
          readOnly
        />
        <TextInput
          label="Read only Input"
          name="read_only_input"
          id="read_only_input"
          placeholder="Readonly input"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.read_only_input || "Readonly input"}
          invalid={
            validation.touched.read_only_input &&
            validation.errors.read_only_input
              ? true
              : false
          }
          readOnly
        />
        <TextInput
          label="Disabled input"
          name="disabled_input"
          id="disabled_input"
          placeholder="Disabled input"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.disabled_input || "Read only input"}
          invalid={
            validation.touched.disabled_input &&
            validation.errors.disabled_input
              ? true
              : false
          }
          disabled={true}
        />
        <TextInput
          label="Input with Icon"
          name="name"
          id="name"
          placeholder="example@gmail.com"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.name || ""}
          invalid={
            validation.touched.name && validation.errors.name ? true : false
          }
          icon="emailIcon"
        />
        <DateInput
          name="input_date"
          id="input_date"
          label="Input Date"
          onChange={(input_date) =>
            validation.setFieldValue("input_date", input_date)
          }
          value={validation.values.input_date}
          mode="single"
        />
        <TextInput
          label="Input Password"
          name="input_password"
          id="input_password"
          placeholder="password"
          type="password"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_password || "password"}
          invalid={
            validation.touched.input_password &&
            validation.errors.input_password
              ? true
              : false
          }
        />
        <TextInput
          label="Form Text"
          name="input_form_text"
          id="input_form_text"
          type="text"
          minLength={8}
          maxLength={20}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_form_text || ""}
          invalid={
            validation.touched.input_form_text &&
            validation.errors.input_form_text
              ? true
              : false
          }
          subtitle="Must be 8-20 characters long"
        />
        <TextInput
          label="Basic Input without auto complete"
          name="input_without_autocomplete"
          id="input_without_autocomplete"
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_without_autocomplete || ""}
          invalid={
            validation.touched.input_without_autocomplete &&
            validation.errors.input_without_autocomplete
              ? true
              : false
          }
        />
        <TextInput
          label="Basic input with auto complete"
          name="input_with_autocomplete"
          id="input_with_autocomplete"
          type="text"
          autocomplete={true}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_with_autocomplete || ""}
          invalid={
            validation.touched.input_with_autocomplete &&
            validation.errors.input_with_autocomplete
              ? true
              : false
          }
        />
        <TextInput
          label="Example Text Area"
          name="input_text_area"
          id="input_text_area"
          type="textarea"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_text_area || ""}
          invalid={
            validation.touched.input_text_area &&
            validation.errors.input_text_area
              ? true
              : false
          }
        />
        <TextInput
          label="Search"
          name="input_search"
          id="input_search"
          placeholder="Search your country..."
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_search || ""}
          invalid={
            validation.touched.input_search && validation.errors.input_search
              ? true
              : false
          }
          icon="searchIcon"
          iconSize={[14, 14]}
        />
        <TextInput
          label="Search"
          name="input_search"
          id="input_search"
          placeholder="Search your country..."
          type="text"
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.input_search || ""}
          invalid={
            validation.touched.input_search && validation.errors.input_search
              ? true
              : false
          }
          icon="searchIcon"
          iconSize={[14, 14]}
          noBorder
        />
        <FilesInput
          label="Default File Input Example"
          value={validation.values.file}
          onChange={(file) => validation.setFieldValue("file", file)}
          invalid={
            validation.touched.file && validation.errors.file ? true : false
          }
          placeholder="No file Chosen"
          name="file"
          id="file"
        />
        <FilesInput
          label="Multiple Files Input Example"
          value={validation.values.file_multiple}
          onChange={(file_multiple) =>
            validation.setFieldValue("file_multiple", file_multiple)
          }
          invalid={
            validation.touched.file_multiple && validation.errors.file_multiple
              ? true
              : false
          }
          placeholder="No file Chosen"
          name="file_multiple"
          id="file_multiple"
          multiple={true}
        />
        <FilesInput
          label="Disabled File Input Example"
          value={validation.values.file_disabled}
          onChange={(file) => validation.setFieldValue("file_disabled", file)}
          invalid={
            validation.touched.file_disabled && validation.errors.file_disabled
              ? true
              : false
          }
          placeholder="No file Chosen"
          name="file"
          id="file"
          disabled
          multiple={true}
        />
        <DateInput
          name="date"
          id="date"
          label="Basic Date Picker"
          onChange={(date) => validation.setFieldValue("date", date)}
          value={validation.values.date}
        />
        <DateInput
          name="date_multiple"
          id="date_multiple"
          label="Multiple Dates"
          onChange={(date_multiple) =>
            validation.setFieldValue("date_multiple", date_multiple)
          }
          value={validation.values.date_multiple}
          type="multiple"
        />
        <DateInput
          name="date_range"
          id="date_range"
          label="Range Date Picker"
          onChange={(date_range) =>
            validation.setFieldValue("date_range", date_range)
          }
          value={validation.values.date_range}
          type="range"
        />
        <TimeInput
          name="time"
          id="time"
          label="Time Picker"
          onChange={(time) => {
            return validation.setFieldValue("time", time);
          }}
          value={validation.values.time}
          mode="timepickr"
          enableTime
          noCalendar
        />

        <SelectInput
          name="select"
          id="select"
          label="Default"
          placeholder="This is a placeholder"
          onChange={(value) => validation.setFieldValue("select", value)}
          value={validation.values.select || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
            {
              value: "1",
              label: "1",
            },
          ]}
        />
        <SelectInput
          name="select"
          id="select"
          label="Select clearable"
          placeholder="Select your Status"
          clearable={true}
          onChange={(value) => validation.setFieldValue("select", value)}
          value={validation.values.select || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
          ]}
        />
        <SelectInput
          name="select_with_search"
          id="select_with_search"
          label="Select with search"
          placeholder="Select your Status"
          searchable={true}
          onChange={(value) =>
            validation.setFieldValue("select_with_search", value)
          }
          value={validation.values.select_with_search || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "Choice 2",
            },
            {
              value: "2",
              label: "2",
            },
            {
              value: "3",
              label: "2",
            },
            {
              value: "4",
              label: "2",
            },
          ]}
        />
        <SelectInputGroups
          searchable={true}
          name="select_with_search"
          id="select_with_search"
          label="Select groups"
          placeholder="Select your Status"
          onChange={(value) =>
            validation.setFieldValue("select_with_search", value)
          }
          value={validation.values.select_with_search || ""}
          options={[
            {
              label: "Fruits",
              options: [
                {
                  value: "1",
                  label: "1",
                },
                {
                  value: "2",
                  label: "Choice 2",
                },
              ],
            },
            {
              label: "Vegetables",
              options: [
                {
                  value: "10",
                  label: "1",
                },
                {
                  value: "4",
                  label: "4",
                },
              ],
            },
          ]}
        />
        <MultipleSelectInput
          label="Multiple select"
          name="multiple_select"
          id="multiple_select"
          hasCloseOnOption
          searchable={true}
          placeholder="Select your Status"
          onChange={(value) =>
            validation.setFieldValue("multiple_select", value)
          }
          value={validation.values.multiple_select || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "Choice 2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
            {
              value: "6",
              label: "6",
            },
            {
              value: "7",
              label: "7",
            },
            {
              value: "8",
              label: "8",
            },
            {
              value: "9",
              label: "9",
            },
            {
              value: "10",
              label: "10",
            },
          ]}
        />
        <MultipleSelectInput
          label="Multiple select without x"
          name="multiple_select"
          id="multiple_select"
          placeholder="Select your Status"
          onChange={(value) =>
            validation.setFieldValue("multiple_select", value)
          }
          value={validation.values.multiple_select || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "Choice 2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
            {
              value: "6",
              label: "6",
            },
            {
              value: "7",
              label: "7",
            },
            {
              value: "8",
              label: "8",
            },
            {
              value: "9",
              label: "9",
            },
            {
              value: "10",
              label: "10",
            },
          ]}
        />
        <MultipleSelectCreatableInput
          label="Multiple creatable"
          placeholder="Search Values"
          searchable
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "Choice 2",
            },
            {
              value: "3",
              label: "3",
            },
            {
              value: "4",
              label: "4",
            },
            {
              value: "5",
              label: "5",
            },
            {
              value: "6",
              label: "6",
            },
            {
              value: "7",
              label: "7",
            },
            {
              value: "8",
              label: "8",
            },
            {
              value: "9",
              label: "9",
            },
            {
              value: "10",
              label: "10",
            },
          ]}
          onChange={(value) =>
            validation.setFieldValue("multiple_select", value)
          }
          value={validation.values.multiple_select || ""}
        />
        <TextPillsInput
          label="Creatable tags"
          placeholder="Search Values"
          searchable
          onChange={(value) =>
            validation.setFieldValue("multiple_select", value)
          }
          value={validation.values.multiple_select || ""}
        />
        <TextPillsInput
          label="Creatable tags with x"
          placeholder="Search Values"
          searchable
          hasCloseOnOption
          onChange={(value) =>
            validation.setFieldValue("multiple_select", value)
          }
          value={validation.values.multiple_select || ""}
        />
        <DropDown
          name="dropdown"
          searchable={true}
          id="dropdown"
          placeholder="Search..."
          onChange={(value) => validation.setFieldValue("dropdown", value)}
          value={validation.values.dropdown || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
          ]}
        >
          <Button style={{ width: "100%" }}>Drop down with search</Button>
        </DropDown>
        <MultipleDropDown
          name="multiple_dropdown"
          id="multiple_dropdown"
          searchable={true}
          placeholder="Search..."
          onChange={(multiple_dropdown) =>
            validation.setFieldValue("multiple_dropdown", multiple_dropdown)
          }
          value={validation.values.select || ""}
          options={[
            {
              value: "1",
              label: "1",
            },
            {
              value: "2",
              label: "2",
            },
          ]}
        >
          <Button style={{ width: "100%" }}>
            Drop down with search and check box
          </Button>
        </MultipleDropDown>
        <DropDownGroups
          name="dropdowm"
          id="dropdowm"
          searchable={true}
          placeholder="Search..."
          onChange={(dropdowm) =>
            validation.setFieldValue("dropdowm", dropdowm)
          }
          value={validation.values.select || ""}
          options={[
            {
              label: "Fruits",
              divider: true,
              options: [
                {
                  value: "1",
                  label: "1",
                  icon: "searchIcon",
                },
                {
                  value: "2",
                  label: "Choice 2",
                  icon: "searchIcon",
                },
              ],
            },
            {
              label: "Something",
              divider: true,
              options: [
                {
                  value: "3",
                  label: "3",
                  icon: "searchIcon",
                },
                {
                  value: "4",
                  label: "4",
                  icon: "searchIcon",
                },
              ],
            },
          ]}
        >
          <Button style={{ width: "100%" }}>
            Drop down with search and groups
          </Button>
        </DropDownGroups>
        <button style={{ marginTop: 50 }} type="submit">
          Submit
        </button>
        <div className="row" style={{ marginTop: 50 }}>
          Wizards
          <Wizards
            wizards={wizards}
            setWizards={setWizards}
            items={wizardItems}
          />
        </div>
      </Form>
    </div>
  );
};

export default BasicElements;

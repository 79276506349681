import React from "react";
import moment from "moment";

const Index = ({ data, small }) => {
  return (
    <div style={{ textAlign: "center" }}>
      <span
        className={`rounded border-primary text-primary ${
          small ? "bodyText_12" : ""
        }`}
      >
        <div>{moment(data).format("YYYY-MM-DD")}</div>
        <div>{moment(data).format("hh:mm:ss")}</div>
      </span>
    </div>
  );
};

export default Index;

import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextInput from "../../../../Components/Common/Inputs/TextInput/TextInput";
import SelectInput from "../../../../Components/Common/Inputs/SelectInput/SelectInput";
import debounce from "../../../../infra/utils/debounce";
import { useSelector } from "react-redux";

import "../../styles.scss";

import DateInput from "../../../../Components/Common/Inputs/DateInput/DateInput";
const SmartOrdersFilters = ({ filters, onFilterChange }) => {
  const [localFilters, setLocalFilters] = useState(filters);
  const [dates, setDates] = useState([]);
  const [datesErrorMsg, setDatesErrorMsg] = useState(false);
  const [clientsList, setClientsList] = useState([]);

  const { clients } = useSelector((state) => state.Info.data);
  const { t } = useTranslation();

  const debouncedFilterChange = useCallback(
    debounce((name, value) => {
      onFilterChange(name, value);
    }, 1000),
    [onFilterChange]
  );

  const handleLocalFilterChange = (name, value) => {
    setLocalFilters((prev) => ({ ...prev, [name]: value }));
    debouncedFilterChange(name, value);
  };

  const handleGFilterChange = (filter) => {
    onFilterChange(filter, !filters[filter]);
  };
  const handleDateRangeChange = (dates) => {
    setDatesErrorMsg(false);

    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];
      const oneMonthLater = new Date(startDate);
      oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);

      setDates(dates);
      if (endDate > oneMonthLater) {
        onFilterChange("dates", dates);
      } else {
        if (endDate) {
          setDatesErrorMsg(true);
        }
      }
    }
  };

  useEffect(() => {
    setClientsList(
      clients?.map((client) => ({
        label: client.name,
        value: client.b2bclientId,
      }))
    );
  }, [clients]);

  const handleRemoveFilter = (filterName) => {
    onFilterChange(filterName, null);
  };

  return (
    <div
      className="smart-orders-filter-wrapper"
      style={{
        maxHeight: "calc(100vh - 185px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <div className="d-flex flex-row justify-content-between">
        <div className="bodyText_2">{t("FILTERS")}</div>
      </div>

      <div className="divider" />

      <div className="filter-container">
        <div className="filter-item">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {`${t("CLIENT")}`}
          </div>
          <div>
            <SelectInput
              style={{ width: "auto" }}
              placeholder={`${t("CLIENT")}...`}
              type="text"
              onChange={(value) => {
                handleLocalFilterChange("client", value);
              }}
              value={localFilters.client || ""}
              icon="searchIcon"
              iconSize={[14, 14]}
              noBorder
              searchable
              clearable
              clearFuntion={() => handleRemoveFilter("client")}
              options={clientsList}
              useSimpleOptions
            />
          </div>
        </div>

        <div className="filter-item">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("REFERENCE_OR_SKU")}
          </div>
          <div>
            <TextInput
              placeholder={`${t("SEARCH_REFERENCE_OR_SKU")}...`}
              type="text"
              onChange={(e) =>
                handleLocalFilterChange("referenceOrSku", e.target.value)
              }
              value={localFilters.referenceOrSku || ""}
              icon="searchIcon"
              iconSize={[14, 14]}
              noBorder
            />
          </div>
        </div>

        <div className="filter-rating">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("RATING")}
          </div>
          <div className="rating-buttons">
            {["G1", "G2", "G3", "G4"].map((filter) => (
              <button
                key={filter}
                className={`rating-button ${filters[filter] ? "active" : ""}`}
                onClick={() => handleGFilterChange(filter)}
              >
                {filter}
              </button>
            ))}
          </div>
        </div>
        <div className="filter-item relative ">
          <div
            className="heading_3 mb-1"
            style={{ color: "rgba(68, 79, 89, 0.60)" }}
          >
            {t("DATE_RANGE")}
          </div>
          <div className="dateinput_range">
            <DateInput
              name="date_range"
              id="date_range"
              placeholder={`${t("DATE_RANGE")}...`}
              onChange={(date_range) => {
                handleDateRangeChange(date_range);
              }}
              clearable
              clearFuntion={() => {
                onFilterChange("dates", null);
                setDates([]);
                setDatesErrorMsg(false);
              }}
              value={dates}
              type="range"
            />
          </div>
          {datesErrorMsg && (
            <div
              className="heading_3 mb-1 error_msg"
              style={{ color: "rgba(196, 17, 20, 0.769)" }}
            >
              {t("SELECT_MORE_THAN_MONTH")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SmartOrdersFilters;

import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import ImageColumn from "./columns/ImageFormat";
import DefaultColumn from "./columns/DefaultFormat";
import BoxNumber from "./columns/BoxNumber";
import DateTime from "./columns/DateTime";
import NumberStatus from "./columns/NumberStatus";
import StatusColumn from "./columns/statusColumn";
import QuantityColumn from "./columns/QuantityColumn";
import QuantityDateColumn from "./columns/QuantityDateColumn";
import CurrencyColumn from "./columns/Currency";
import ProgressColumn from "./columns/progressColumn";
import PaginationComponent from "../PaginationComponent";
import TableButton from "../Button";
import ButtonIcon from "../Buttons/ButtonIcon";
import Icon from "../icon";
import MultipleDropDown from "../Inputs/MultipleDropDown/MultipleDropDown";
import DropDownText from "../Inputs/DropDownText/DropDownText";
import DropDown from "../Inputs/DropDown/DropDown";
import DropDownDate from "../Inputs/DropDownDate/DropDownDate";
import Badge from "../Badges/Badge";
import BatteryBadge from "../Badges/BatteryBadge";
import PropTypes from "prop-types";

const Table = ({
  minHeight,
  tableInfo,
  tableColumns,
  columnFilters,
  setColumnFilters,
  currentPage,
  setCurrentPage,
  rowsPerPage,
  setRowsPerPage,
  totalItems,
  setList,
  loading,
  small,
  paginationOptions,
  onRowClick,
  expandable,
  expandedRowRender,
  onRowExpand,
  onRowCollapse,
  footer,
  sortData,
  dataType,
}) => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const totalPages = Math.ceil(totalItems / rowsPerPage);

  const onRowEditComplete = (e) => {
    let _products = [...tableInfo];
    const { newData, index } = e;
    _products[index] = newData;
    setList(_products);
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
    setCurrentPage(1);
  };

  const isFilterActive = (filterName) => {
    const filter = columnFilters[filterName];

    if (filterName === "date") {
      return (
        filter !== undefined &&
        filter !== null &&
        filter.length > 0 &&
        !(filter.length === 2 && filter[0] === null && filter[1] === null)
      );
    }

    return filter !== undefined && filter !== null && filter.length > 0;
  };

  const rowExpansionTemplate = (data) => {
    return expandedRowRender ? expandedRowRender(data) : null;
  };

  return (
    <div className="table-wrapper" style={{ backgroundColor: "transparent" }}>
      <DataTable
        style={{
          minHeight: minHeight ? minHeight : "auto",
        }}
        size={tableColumns.size}
        scrollable={tableColumns.scroll.scrollable}
        scrollHeight={tableColumns.scroll.scrollableHeight}
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
        editMode="row"
        value={tableInfo}
        globalFilterFields={tableColumns.globalFilterFields}
        className="custom-datatable"
        onRowEditComplete={onRowEditComplete}
        paginator={false}
        rowClassName="clickable-row"
        onRowClick={(e) => onRowClick(e.data)}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        onRowExpand={onRowExpand}
        onRowCollapse={onRowCollapse}
        rowExpansionTemplate={rowExpansionTemplate}
        expandable={String(expandable)}
      >
        {expandable && <Column expander style={{ width: "3em" }} />}

        {tableColumns?.columns?.map((column, index) => {
          return (
            <Column
              selectionMode={column?.type === "check" ? "multiple" : undefined}
              key={index}
              field={column.field}
              filter={column.filter}
              className={small ? "custom-column-small" : "custom-column"}
              showFilterMenu={false}
              footer={
                tableColumns.footer ? (
                  loading ? (
                    <></>
                  ) : (
                    <div
                      className="table-column-filter-wrapper-footer"
                      style={{
                        justifyContent: column.align ? column.align : "center",
                        padding: 0,
                      }}
                    >
                      {column.footer}
                    </div>
                  )
                ) : undefined
              }
              header={
                <div
                  className="table-column-filter-wrapper"
                  style={{
                    justifyContent: column.align
                      ? column.align
                      : "space-between",
                    cursor: column.sortable && "pointer",
                  }}
                  onClick={() => {
                    column.sortable && sortData(column.field);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: 14,
                      lineHeight: "normal",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    {column.header}
                    {column.sortable && (
                      <Icon
                        className={"table-column-sort-icon"}
                        icon="Sort"
                        size={[15, 15]}
                      />
                    )}
                  </div>
                  {column?.columnFilter === "single" && (
                    <DropDown
                      top={13}
                      style={{ marginTop: "0px !important" }}
                      searchable={true}
                      placeholder="Search..."
                      onChange={(e) => {
                        setColumnFilters({
                          ...columnFilters,
                          [column?.filterName]: e?.length === 0 ? "" : e,
                        });
                      }}
                      value={
                        columnFilters[column?.filterName] !== undefined
                          ? columnFilters[column?.filterName]
                          : undefined
                      }
                      options={column.filters}
                    >
                      <div>
                        <Icon
                          className={`table-column-filter-icon ${
                            isFilterActive(column?.filterName) ? "selected" : ""
                          }`}
                          icon="columnFilterIcon"
                          size={[15, 15]}
                        />
                      </div>
                    </DropDown>
                  )}
                  {column?.columnFilter === "multiple" && (
                    <MultipleDropDown
                      left={column.left}
                      top={13}
                      style={{ marginTop: "0px !important" }}
                      searchable={true}
                      placeholder="Search..."
                      onChange={(e) => {
                        setColumnFilters({
                          ...columnFilters,
                          [column?.filterName]: e.length === 0 ? "" : e,
                        });
                      }}
                      value={
                        columnFilters[column?.filterName] !== undefined
                          ? columnFilters[column?.filterName]
                          : undefined
                      }
                      options={column.filters}
                    >
                      <div>
                        <Icon
                          className={`table-column-filter-icon ${
                            isFilterActive(column?.filterName) ? "selected" : ""
                          }`}
                          icon="columnFilterIcon"
                          size={[15, 15]}
                        />
                      </div>
                    </MultipleDropDown>
                  )}
                  {column?.columnFilter === "text" && (
                    <div style={{ position: "relative" }}>
                      <DropDownText
                        placeholder="Search..."
                        onChange={(e) => {
                          setColumnFilters({
                            ...columnFilters,
                            [column?.filterName]: e.length === 0 ? "" : e,
                          });
                        }}
                        value={
                          columnFilters[column?.filterName] !== undefined
                            ? columnFilters[column?.filterName]
                            : undefined
                        }
                      >
                        <div>
                          <Icon
                            className={`table-column-filter-icon ${
                              isFilterActive(column?.filterName)
                                ? "selected"
                                : ""
                            }`}
                            icon="columnFilterIcon"
                            size={[15, 15]}
                          />
                        </div>
                      </DropDownText>
                    </div>
                  )}
                  {column?.columnFilter === "date" && (
                    <div style={{ position: "relative" }}>
                      <DropDownDate
                        placeholder="Search..."
                        onChange={(e) => {
                          setColumnFilters({
                            ...columnFilters,
                            [column?.filterName]: e.length === 0 ? "" : e,
                          });
                        }}
                        value={
                          columnFilters[column?.filterName] !== undefined
                            ? columnFilters[column?.filterName]
                            : undefined
                        }
                      >
                        <div>
                          <Icon
                            className={`table-column-filter-icon ${
                              isFilterActive(column?.filterName)
                                ? "selected"
                                : ""
                            }`}
                            icon="columnFilterIcon"
                            size={[15, 15]}
                          />
                        </div>
                      </DropDownDate>
                    </div>
                  )}
                </div>
              }
              alignHeader={"center"}
              body={(rowData) => {
                if (loading) {
                  return (
                    <div
                      style={{
                        minHeight: 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="placeholder-md" />
                    </div>
                  );
                } else {
                  const matchedColumn = tableColumns?.columns?.find(
                    (col) => col.field === column.field
                  );

                  if (matchedColumn) {
                    switch (matchedColumn.type) {
                      case "status-tag":
                        return <StatusColumn data={rowData} />;
                      case "image-component":
                        return <ImageColumn data={rowData} />;
                      case "box-number":
                        return (
                          <BoxNumber
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "progress":
                        return (
                          <ProgressColumn
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "quantity":
                        return (
                          <QuantityColumn
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "quantity-date":
                        return (
                          <QuantityDateColumn
                            data={rowData[column.field]}
                            date={rowData.forecastDate}
                            products={tableInfo}
                          />
                        );
                      case "number-status":
                        return (
                          <NumberStatus
                            data={rowData[column.field]}
                            products={tableInfo}
                          />
                        );
                      case "badge":
                        return (
                          <Badge
                            text={rowData[column.field]}
                            color={rowData[column.field]}
                          />
                        );
                      case "battery-badge":
                        return <BatteryBadge type={rowData[column.field]} />;
                      case "currency":
                        return <CurrencyColumn data={rowData} />;
                      case "date-time":
                        return (
                          <DateTime
                            small={small}
                            data={rowData[column.field]}
                          />
                        );
                      default:
                        return (
                          <div
                            style={{ textAlign: column.align }}
                            className={`${small ? "heading_6" : ""}`}
                          >
                            {rowData[column.field]}
                          </div>
                        );
                    }
                  } else {
                    return (
                      <DefaultColumn
                        data={rowData[column.field]}
                        products={tableInfo}
                      />
                    );
                  }
                }
              }}
            />
          );
        })}

        {tableColumns.editrow.hasEdit === true && (
          <Column
            rowEditor
            headerStyle={{ width: 100 }}
            bodyStyle={{ textAlign: "center" }}
            body={(rowData, options) => {
              if (loading) {
                return <div className="placeholder-md" />;
              } else {
                return (
                  <>
                    {options.rowEditor?.editing ? (
                      <>
                        <TableButton
                          iconName="DotIcon"
                          size="sm"
                          colorType="warning"
                          outline
                          onSaveClick={(e) =>
                            options.rowEditor?.onSaveClick &&
                            options.rowEditor?.onSaveClick(e)
                          }
                          tooltipOptions={{ position: "top" }}
                        />
                        <TableButton
                          iconName="DotIcon"
                          size="sm"
                          colorType="warning"
                          outline
                          onClick={(e) =>
                            options.rowEditor?.onCancelClick &&
                            options.rowEditor?.onCancelClick(e)
                          }
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          zIndex: 999,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <ButtonIcon
                          type="secondary"
                          icon="treeDots"
                          color="yellow"
                        />
                      </div>
                    )}
                  </>
                );
              }
            }}
          />
        )}
        <Column
          headerStyle={{ width: "15px" }}
          className={small ? "custom-column-small" : "custom-column"}
        />
      </DataTable>
      {footer && (
        <PaginationComponent
          paginationOptions={paginationOptions}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
          rowsPerPage={rowsPerPage}
          products={tableInfo}
          totalItems={totalItems}
          handleRowsPerPageChange={handleRowsPerPageChange}
          dataType={dataType}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableInfo: PropTypes.array,
  tableColumns: PropTypes.object,
  columnFilters: PropTypes.object,
  setColumnFilters: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  rowsPerPage: PropTypes.number,
  setRowsPerPage: PropTypes.func,
  totalItems: PropTypes.number,
  setList: PropTypes.func,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  paginationOptions: PropTypes.array,
  onRowClick: PropTypes.func,
  expandable: PropTypes.bool,
  expandedRowRender: PropTypes.func,
  onRowExpand: PropTypes.func,
  onRowCollapse: PropTypes.func,
  footer: PropTypes.bool,
  dataType: PropTypes.string,
};

Table.defaultProps = {
  minHeight: "auto",
  tableInfo: [],
  tableColumns: {},
  columnFilters: {},
  setColumnFilters: () => {},
  currentPage: null,
  setCurrentPage: () => {},
  rowsPerPage: 10,
  setRowsPerPage: () => {},
  totalItems: 0,
  setList: () => {},
  loading: null,
  small: false,
  paginationOptions: [],
  onRowClick: () => {},
  expandable: false,
  expandedRowRender: () => {},
  onRowExpand: () => {},
  onRowCollapse: () => {},
  footer: true,
  dataType: "products",
};

export default Table;

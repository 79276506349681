import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { useState } from "react";

import "../styles.scss";

const ResetPassword = ({ onSubmit }) => {
  const [passwordShow, setPasswordShow] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const { t } = useTranslation();

  const validation = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(t("PLEASE_ENTER_NEW_PASSWORD"))
        .min(8, t("NEW_PASSWORD_LENGTH_RULE")),
      passwordConfirmation: Yup.string()
        .required(t("PLEASE_CONFIRM_NEW_PASSWORD"))
        .oneOf([Yup.ref("password"), null], t("PASSWORDS_MUST_MATCH")),
    }),
    onSubmit: onSubmit,
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
      className="d-flex justify-content-center"
    >
      <Col md={8} lg={6} xl={5} className="login-form-card">
        <Card className="mt-2">
          <CardBody className="login-card-body">
            <div className="text-center mt-2">
              <h5 className="text-primary">{t("CREATE_NEW_PASSWORD")}</h5>
              <p className="text-muted">{t("NEW_PASSWORD_RULE")}</p>
            </div>
            <div className="p-2 mt-4">
              <div className="mb-3">
                <Label htmlFor="password" className="form-label label-font">
                  {t("PASSWORD")}
                  <span className="text-danger"> *</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    autoFocus
                    name="password"
                    className="form-control"
                    placeholder={t("ENTER_PASSWORD")}
                    type={passwordShow ? "text" : "password"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                  {validation.touched.password &&
                  validation.errors.password ? null : (
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  )}
                </div>
                <div className="lh-13 fs-11 fw-475 text-muted">
                  {t("NEW_PASSWORD_LENGTH_RULE")}
                </div>
              </div>
              <div className="mb-3">
                <Label
                  htmlFor="passwordConfirmation"
                  className="form-label label-font"
                >
                  {t("CONFIRM_PASSWORD_LABEL")}
                  <span className=" text-danger"> *</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    name="passwordConfirmation"
                    className="form-control"
                    placeholder={t("CONFIRM_PASSWORD")}
                    type={passwordShow ? "text" : "password"}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.passwordConfirmation || ""}
                    invalid={
                      validation.touched.passwordConfirmation &&
                      validation.errors.passwordConfirmation
                        ? true
                        : false
                    }
                  />
                  {validation.touched.passwordConfirmation &&
                  validation.errors.passwordConfirmation ? (
                    <FormFeedback type="invalid">
                      {validation.errors.passwordConfirmation}
                    </FormFeedback>
                  ) : null}
                  {validation.touched.passwordConfirmation &&
                  validation.errors.passwordConfirmation ? null : (
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="form-check checkbox-gap">
                <Input
                  className={
                    "form-check-input " + (rememberMe && "bg-info border-info")
                  }
                  type="checkbox"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                  id="auth-remember-check"
                />
                <Label
                  className="form-check-label fw-normal"
                  htmlFor="auth-remember-check"
                >
                  {t("REMEMBER_ME")}
                </Label>
              </div>
              <div className="mt-4">
                <Button
                  color="info"
                  className="btn w-100 text-white"
                  type="submit"
                >
                  {t("RESET_PASSWORD")}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
        <div className="mt-4 text-center">
          <p className="mb-0">
            {t("WAIT_I_REMEMBER_PASSWORD")}
            <Link
              to="/login"
              className="fw-semibold click-here text-decoration-underline"
            >
              {t("CLICK_HERE")}
            </Link>{" "}
          </p>
        </div>
      </Col>
    </Form>
  );
};

export default ResetPassword;

import React from "react";

const ColorBadge = ({ color }) => {
  return (
    <div className="base-color-badge-wrapper">
      <div
        className="base-color-badge-color"
        style={{ backgroundColor: color ? color : "black" }}
      />
    </div>
  );
};

export default ColorBadge;

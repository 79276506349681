import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";
import { useState } from "react";

import "../styles.scss";

const LockScreen = () => {
  const [passwordShow, setPasswordShow] = useState(false);
  const { t } = useTranslation();

  const validation = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("PLEASE_ENTER_NEW_PASSWORD")),
    }),
    onSubmit: (value) => {
      console.log("Loging In with: ", value);
    },
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
      action="#"
    >
      <Row className="justify-content-center">
        <Col md={8} lg={6} xl={5} className="login-form-card">
          <Card className="mt-2">
            <CardBody className="login-card-body">
              <div className="text-center mt-2 d-flex flex-column align-items-center">
                <h5 className="text-primary">{t("LOCK_SCREEN")}</h5>
                <p className="text-muted">{t("UNLOCK_SCREEN_SUBTITLE")}</p>
                <img
                  className="rounded-circle header-profile-user avatar"
                  src={"https://placehold.co/400x400"}
                  alt="Header Avatar"
                />
                <h4 className="text-primary">{t("USERNAME")}</h4>
              </div>
              <div className="mb-3">
                <Label htmlFor="password" className="form-label label-font">
                  {t("PASSWORD")}
                  <span className=" text-danger"> *</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    autoFocus
                    name="password"
                    className="form-control"
                    placeholder={t("ENTER_PASSWORD")}
                    type={passwordShow ? "text" : "password"}
                    onChange={validation.handleChange}
                    value={validation.values.password || ""}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}
                  {validation.touched.password &&
                  validation.errors.password ? null : (
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                      id="password-addon"
                      onClick={() => setPasswordShow(!passwordShow)}
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                  )}
                </div>
              </div>
              <div className="text-center mt-4">
                <button className="btn btn-success w-100" type="submit">
                  {t("UNLOCK")}
                </button>
              </div>
            </CardBody>
          </Card>
          <div className="mt-4 text-center">
            <p className="mb-0">
              {t("NOT_YOU")}{" "}
              <Link to="/login" className="fw-semibold">
                <span
                  style={{
                    color: "#64A0E3",
                    textDecoration: "underline",
                  }}
                >
                  {t("SIGN_IN")}
                </span>
              </Link>
            </p>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default LockScreen;

import React from "react";
import { Button } from "reactstrap"; // Renomeando o Button para evitar conflitos
import Icon from "../icon";

const TableButton = ({
  size,
  colorType,
  iconName,
  outline,
  onInitClick,
  onSaveClick,
  onCancelClick,
  iconColor,
}) => {
  return (
    <Button
      className="no-hover-animation"
      style={{ height: "30px", width: "30px" }}
      size={size}
      color={colorType}
      outline={outline}
      onClick={(e) => {
        if (onInitClick) {
          onInitClick(e);
        } else if (onSaveClick) {
          onSaveClick(e);
        } else if (onCancelClick) {
          onCancelClick(e);
        }
      }}
    >
      <Icon color={iconColor} size={[15, 15]} icon={iconName} />
    </Button>
  );
};

export default TableButton;

import React from "react";
import Icon from "../icon";

const BatteryBadge = ({ text, type }) => {
  const handleType = () => {
    switch (type) {
      case 1:
        return "battery1";
      case 2:
        return "battery2";
      case 3:
        return "battery3";
      case 4:
        return "battery4";
      case 5:
        return "battery5";
      case 6:
        return "battery6";
      default:
        return "battery6";
    }
  };

  const handleText = () => {
    switch (type) {
      case 1:
        return "0%";
      case 2:
        return "20%";
      case 3:
        return "40%";
      case 4:
        return "60%";
      case 5:
        return "80%";
      case 6:
        return "100%";
      default:
        return "100%";
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: 4 }}>
      <Icon size={[36, 16]} icon={handleType()} />
      <div className="heading_7" style={{ color: "#566069" }}>
        {text ? text : handleText()}
      </div>
    </div>
  );
};

export default BatteryBadge;
